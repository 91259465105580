import moment from 'moment';
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";

import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import Loader from "@/shared/FullScreenLoader/Loading";
import NcImage from "@/shared/NcImage/NcImage";
import { useTypedQuery } from '@/hooks/useZeus';
import { useParams, useSearchParams } from 'react-router-dom';
import { useCartContext } from '@/context/CartContext';
import PriceSummary from '@/components/PriceSummary';
import { fireEventOnce, mapAdboardToGtagItem } from '@/lib/utils';

export interface PayPageProps {
  className?: string;
}

const PayDoneCampaignPage: FC<PayPageProps> = ({ className = "" }) => {

  const [searchParams] = useSearchParams();
  const  {clearCart} = useCartContext()
  const params = useParams();
  const campaignId = searchParams.get('campaignId')||params.campaignId;

  const { data } = useTypedQuery({
    campaign:[{
      where:{
        id:campaignId
      }
    },{
      assets:[{},{
        url:true,
        type:true,
        name:true
      }],
      user:{
        email:true,
      },
      priceSummary:{
        discount:true,
        days:true,
        serviceCharge:true,
        payableRent:true,
        rentPerDay:true,
        totalRent:true,
      },
      id:true,
      selectedDates:{
        startDate:true,
        endDate:true
      },
      bookings:[{},{
        id:true,
        adBoard:{
          id:true,
          pricePerDay:true,
          city:true,
          galleryImgs:true,
          title:true
        }
      }]
    }]
  })

  useEffect(()=>{
    if(data?.campaign?.id && data.campaign){
      fireEventOnce("purchase", {
        transaction_id: data.campaign.id,
        value: data.campaign.priceSummary?.payableRent,
        tax: data.campaign.priceSummary?.serviceCharge,
        currency: "INR",
        items: data.campaign.bookings.map(booking=> 
          mapAdboardToGtagItem(booking.adBoard,data.campaign?.selectedDates as any)
        )
      });
    }
  },[data?.campaign])
  
  React.useEffect(()=>{
    clearCart()
  },[])

  if (!data || !data.campaign) {
    return <Loader fullScreen />
  }

  const {bookings,selectedDates,assets} = data.campaign;

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">

        <Helmet>
          <title>Confirmation | AdBoard Booking</title>
        </Helmet>

        <h2 className="text-3xl lg:text-4xl font-semibold">
          Congratulation 🎉
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Your booking</h3>

          {bookings.map(booking=>{
            const {adBoard} = booking;
            return <div id={booking.id} className="flex flex-col sm:flex-row sm:items-center">
              <div className="flex-shrink-0 w-full sm:w-40">
                <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                  <NcImage src={adBoard.galleryImgs[0]} />
                </div>
              </div>
              <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
                <div>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
      Screen in {adBoard.city}
                  </span>
                  <span className="text-base sm:text-lg font-medium mt-1 block">
                    {adBoard.title}
                  </span>
                </div>
                {/* <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
    2 beds · 2 baths
  </span> */}
                <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                {/* <StartRating /> */}
              </div>
            </div>
          })}
          
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {moment(selectedDates?.startDate).format('LL')} - {moment(selectedDates?.endDate).format('LL')}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Booking detail</h3>
          <div className="flex flex-col space-y-4"> 
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Booking date</span>
              <span className="flex-1 text-right font-medium text-neutral-900 dark:text-neutral-100">
                {moment().format('LL')}
              </span>
            </div>
            
            <PriceSummary priceSummary={data.campaign?.priceSummary}></PriceSummary>
          </div>
        </div>
 
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Uploaded assets</h3>
          <div className='flex gap-4'>
            {assets?.map(item => {
              if(item.type?.includes('image')){
                return(<figure className="max-w-lg w-80">
                  <img className="max-w-full h-auto rounded-lg" src={item.url} alt={item.name||""} />
                </figure>)
              }else{
                return <video className="w-80 rounded-lg" controls>
                  <source src={item.url} type="video/mp4"/>
                Your browser does not support the video tag.
                </video>
              }
            })} 
          </div>
        </div>
        <div>
          <ButtonPrimary href={"/campaign/"+campaignId}>Manage booking</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayDoneCampaignPage;
