
import FileUpload from '@/components/FileUpload'
import { useTypedQuery } from '@/hooks/useZeus'
import Input from '@/shared/Input/Input'
import Select from '@/shared/Select/Select'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FileData } from '../MediaAssetsPage/useMediaAssets'

export default function CampaignDetailBox(props:{readonly?:boolean,onChange:(args:Record<string,any>)=>void}) {
  const {register,watch,getValues,setValue} = useForm();
  const {data:businessCategoriesData} = useTypedQuery({
    businessCategories:[{},{
      id:true,
      name:true,
    }]
  });

  useEffect(()=>{
    const values = getValues();
    props.onChange?.({
      name:values['name'],
      industry:values['industry'],
      files:values['files']||[]
    })
  },[watch('name'),watch('industry'),watch('files')])

  return (
    <>
      <div className="space-y-1 mt-4">
        <h2 className='text-lg mb-2 font-semibold'>Campaign name*</h2>
        <Input {...register('name')} placeholder="Name" disabled={false}  />
      </div>

      <div className="space-y-1 mt-4">
        <h2 className='text-lg mb-2 font-semibold'>Industry*</h2>
        {!props.readonly ? 
          <Select {...register("industry", { required: true })} placeholder="Phone number" >
            <option value="">Select</option>
            {businessCategoriesData?.businessCategories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>:<Input disabled={true} value={businessCategoriesData?.businessCategories.find(item=>item.id == watch('industry'))?.name} />
        }
      </div>
      <div className="space-y-1 mb-4">
        <FileUpload onFileSelection={(files: (File|FileData)[]) => setValue('files',files)} />
      </div>
      
    </>
    
  )
}
