import LoadingButton from "@/components/LoadingButton";
import { XClear } from "@/containers/Bookings/RenderXClear";
import { FC } from "react";
import { Link } from "react-router-dom";
import Badge from "@/shared/Badge/Badge";
import { FileData } from "./useMediaAssets";
import { FileStatus } from "@/generated/zeus";
import { BriefcaseIcon } from "@heroicons/react/24/outline";

export interface PropertyCardHProps {
  className?: string;
  thumbnailView?: boolean;
  onDelete?:()=>void;
  data: FileData;
  onCardSelect?:(file:FileData)=>void
}

function getColor(status:FileStatus){
  switch(status){
  case FileStatus.APPROVED:
    return "green";
  case FileStatus.REQUEST_APPROVAL:
    return "yellow";
  case FileStatus.REJECTED:
    return "red";
  }
}

export const AssetCard: FC<PropertyCardHProps> = ({
  className = "",
  data ,
  onDelete,
  onCardSelect
}) => {

  function handleDelete(){
    const isYes = window.confirm('Are you sure you want to delete this file?');

    if(isYes){
      onDelete?.()
    }
  }

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        <div className={`w-full lg:w-60 h-auto lg:h-40 bg-gray-100 rounded-2xl overflow-hidden will-change-transform`}>
          <div className="Sirv" data-src={data?.url}></div>
        </div>
      </div>
    );
  }; 

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2">

        {/* ---- */}
      
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-database"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {((data?.size||0)/1024/1024).toFixed(2)} MB
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-stopwatch"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.meta?.duration? data?.meta?.duration.toFixed(0):20} sec
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <BriefcaseIcon className="w-4 h-4"/>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
          Active bookings: {data.bookings?.length}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.meta?.width} * {data?.meta?.height}
          </span>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="inline-flex space-x-3">
            {data?.status && (
              <Badge name={data?.status} color={getColor(data.status)} className="" />
            )}
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-share-alt"></i>
                  <span className="ml-1">{data?.type}</span>
                </div>
              }
            />
          </div>
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-medium capitalize">
              <Link to={"/asset/"+data?.id}>
                <span className="line-clamp-2">{data?.name}</span>
              </Link>
            </h2>
          </div>
          {renderTienIch()}
          {onCardSelect && data.status!=FileStatus.REJECTED &&
          <div className="flex w-full justify-between items-end">
            <LoadingButton onClick={()=>onCardSelect(data)}>
              Select
            </LoadingButton>
          </div>
          }
          
        </div>
        
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      
      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
        
      </div>
      {onDelete && data.bookings.length==0 && 
      <div onClick={handleDelete} className="right-5 top-5 sm:right-3 sm:top-3 absolute">
        <XClear></XClear>
      </div>
      }
    </div>
  );
};

