import React, { useEffect, useState } from "react";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { toast } from "react-toastify";

type FirebaseContextValue={
  user: firebase.User | null;
  firebase: typeof firebase;
  token: string | null;
  loading: boolean;
}
const FirebaseContext = React.createContext<FirebaseContextValue | null>(null);

// Configure Firebase.
const firebaseConfig = {
  apiKey: "AIzaSyDGUD68J7kdsQS3LJ2JIMvrrxvw8-kIQDw",
  authDomain: "adboard-booking.firebaseapp.com",
  projectId: "adboard-booking",
  storageBucket: "adboard-booking.appspot.com",
  messagingSenderId: "821161764842",
  appId: "1:821161764842:web:e7101bc98529bbbd433493",
  measurementId: "G-QL1DTXHX79"
};
firebase.initializeApp(firebaseConfig);

export function FirebaseProvider({children}: {children: React.ReactNode}){
  const [user,setUser] = useState<firebase.User|null>(null);
  const [token,setToken] = useState<string|null>(null); 
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
      
      if(!!user){
        await firebase.auth().currentUser?.getIdToken(/* forceRefresh */ true)
          .then(async function(idToken) {
            const user = firebase.auth().currentUser;
            if(user){
              sessionStorage.setItem("token",idToken);
            }
            setUser(user) 
            setToken(idToken)
          }).catch(function() {
            toast.error('Something went wrong with authentication')
          }).finally(()=>{
            setLoading(false)
          });
      }else{
        setLoading(false)
      }
      
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  },[])

  return <FirebaseContext.Provider value={{user,firebase,token,loading}}>
    {children}
  </FirebaseContext.Provider>
}

export const useFirebaseContext = () => {
  const context = React.useContext(FirebaseContext);
  if(context === null){
    throw new Error('useFirebaseContext must be used within a FirebaseProvider');
  }
  return context;
}
