import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react'

export default function Box(props: { classname?:string, collapsible?: boolean,collapsed?:boolean, title?: string, children?: React.ReactNode, buttons?: React.ReactNode }) {
  const [collapsed, setCollapsed] = React.useState(props.collapsed);

  useEffect(()=>{
    setCollapsed(props.collapsed)
  },[props.collapsed])

  return (
    <div className="bg-white/80 dark:bg-black w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700  px-0 sm:p-4 xl:p-6 xl:py-6 lg:shadow-lg">
      <div className='flex justify-between items-center'>
        <div>
          {props.title &&
            <h2 className="text-2xl font-semibold cursor-pointer" onClick={()=>setCollapsed(!collapsed)} >
              {props.title}
            </h2>
          }
        </div>
        <div>
          {props.buttons}
          {props.collapsible &&
          <span onClick={()=>setCollapsed(!collapsed)} className='cursor-pointer'>{collapsed ?
            <ChevronDownIcon className='w-4 h-4'/>: <ChevronDownIcon className='w-4 h-4 transform rotate-180'/>
          }</span>
          }
        </div>

      </div>

      {!collapsed && <div className={'pt-2 '+props.classname}>
        {props.children}
      </div>
      }
    </div>
  )
}
