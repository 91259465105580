import React, { useEffect, useState } from 'react'

type CartContextProps = {
  adBoards: AdboardFragment[],
  clearCart: () => void,
  addToCart: (adBoardId: string) => void,
  removeFromCart: (adBoardId: string) => void,
  toggleFromCart: (adBoardId: string) => void,
  isInCart: (adBoardId: string) => boolean,
}

export const CartContext = React.createContext<CartContextProps>({} as CartContextProps);

import { GraphQLTypes, InputType, Selector } from "@/generated/zeus";

export const adBoardSelector = Selector('AdBoard')({
  id:true,
});

export type AdboardFragment = InputType<GraphQLTypes['AdBoard'], typeof adBoardSelector>;

export default function CartProvider(props: { children: React.ReactNode }) {

  const [adBoards, setAdboards] = useState<AdboardFragment[]>([]);

  async function addToCart(adBoardId: string) {
    
    if (!adBoards.some(item=>item.id == adBoardId)) {
      if(adBoardId){
        setAdboards(prevAdboards =>[...prevAdboards, {id:adBoardId}]);
      }
    }
  }

  function removeFromCart(adBoardId: string) {
    if (adBoards.some(item=>item.id == adBoardId)) {
      setAdboards(adBoards.filter(item => item.id != adBoardId));
    }
  }

  useEffect(()=>{
    const cart = localStorage.getItem('cart');
    if(cart) {
      const {adBoards} = JSON.parse(cart);
      setAdboards(adBoards);
    }
  },[])
  
  useEffect(()=>{
    localStorage.setItem('cart', JSON.stringify({adBoards}));
  },[adBoards])

  function clearCart() {
    localStorage.setItem('cart', JSON.stringify({adBoards:[]}));
    setAdboards([]);
  }

  function toggleFromCart(adBoardId: string) {
    if (adBoards.some(item=>item.id == adBoardId)) {
      removeFromCart(adBoardId);
    } else {
      addToCart(adBoardId);
    }
  }
  function isInCart(adBoardId: string) {
    return adBoards.some(item=>item.id == adBoardId);
  }

  return (
    <CartContext.Provider value={{ adBoards, addToCart, removeFromCart,clearCart,toggleFromCart,isInCart}}>
      {props.children}
    </CartContext.Provider>
  )
}

export const useCartContext = () => React.useContext(CartContext);
