import { Popover, Transition } from "@headlessui/react";
import {
  ArrowRightOnRectangleIcon,
  LifebuoyIcon, UserCircleIcon,
  VideoCameraIcon,
  BookOpenIcon,
  TicketIcon,
  TvIcon
} from "@heroicons/react/24/outline";
import useUserContext from "@/hooks/useUserContext";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import Avatar from "@/shared/Avatar/Avatar";

const solutions = [
  {
    name: "Account",
    href: "/account",
    icon: UserCircleIcon,
  },
  {
    name: "Media library",
    href: "/media-assets",
    icon: VideoCameraIcon,
  },
  {
    name: "My bookings",
    href: "/bookings",
    icon: BookOpenIcon,
  },
  {
    name: "My campaigns",
    href: "/campaigns",
    icon: TicketIcon,
  },
];

export default function AvatarDropdown() {

  const {user} = useUserContext()
  const solutionsFoot = [
    {
      name: "Help",
      href: "/contact",
      icon: LifebuoyIcon,
    },
    {
      href: "/logout",
      name: "Logout",
      icon: ArrowRightOnRectangleIcon,
    },
  ];

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ close }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" imgUrl={user?.image}/>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {solutions.map((item, index) => (
                      <Link
                        onClick={()=>close()}
                        key={index}
                        to={item.href}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                   
                    <Link
                      to={"https://screens.adboardbooking.com"}
                      className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <TvIcon aria-hidden="true" className="w-6 h-6" />
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium ">Switch to publisher</p>
                      </div>
                    </Link>
                    
                  </div>
                  <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {solutionsFoot.map((item, index) => (
                      <Link
                        to={item.href}
                        key={index}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
