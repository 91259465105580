import React from 'react'
import { FEES, FEES_AFTER_DISCOUNT, GST } from '@/contains/contants'

export default function PriceSummary({
  priceSummary
}: {
    priceSummary?: {
        rentPerDay: number
        days: number,
        totalRent: number,
        serviceCharge: number,
    }
}) {

  if (!priceSummary) {
    return null
  }
  const total = priceSummary.totalRent;
  const gst = priceSummary.serviceCharge;
  const totalWithGST = total + gst;
  return (
    <div>
      <div className="flex flex-col space-y-4">
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>₹{priceSummary.rentPerDay} x {priceSummary.days} days</span>
          <span>₹{total}</span>
        </div>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>GST (@{GST})</span>
          <span>₹{gst}</span>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex justify-between font-semibold">
          <span>Total</span>
          <span>₹{totalWithGST}</span>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>Payable now (platform fees)</span>
          <span>₹{FEES_AFTER_DISCOUNT} <s>₹{FEES}</s></span>
        </div>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>Payable on booking approval</span>
          <span>₹{totalWithGST}</span>
        </div>

      </div>
    </div>
  )
}
