import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { Rocket, Target, BarChart2, DollarSign, Zap } from 'lucide-react';

const EasyOnlineBillboardBooking = () => {
  const title = "You Won't Believe How Easy Online Billboard Booking Can Be!";
  const description = "Discover how online billboard booking is revolutionizing outdoor advertising. Learn about AI-powered targeting, real-time analytics, and eco-friendly solutions for your next ad campaign.";
  const keywords = "billboard booking, outdoor advertising, digital marketing, AI targeting, real-time analytics";
  const url = "https://yourdomain.com/blog/easy-online-billboard-booking";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <link rel="canonical" href={url} />
      </Helmet>

      <div className="max-w-4xl mx-auto p-4 space-y-6">
        <h1 className="text-4xl font-bold text-center mb-8">{title}</h1>
        
        <Card>
          <CardHeader>
            <CardTitle>The Old Way? More Like the "Ugh" Way</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Remember the days of endless phone calls, email chains longer than your grocery list, and paperwork that could wallpaper your office? Yeah, we don't miss that either.</p>
          </CardContent>
        </Card>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">Enter the Digital Revolution: Billboard Booking at Your Fingertips</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Rocket className="mr-2" /> Speed Demon Booking
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>With just a few clicks, you can browse available billboards, check out their specs, and lock in your spot.</p>
            </CardContent>
          </Card>
          
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Target className="mr-2" /> Laser-Focused Targeting
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>Our AI-powered platform lets you pinpoint your audience with surgical precision.</p>
            </CardContent>
          </Card>
          
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <BarChart2 className="mr-2" /> Real-Time Analytics
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>Get juicy, real-time data on views, engagement, and even the occasional bird that decided your billboard was the perfect perch.</p>
            </CardContent>
          </Card>
          
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <DollarSign className="mr-2" /> Budget-Friendly Options
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>Whether you're a scrappy startup or a Fortune 500 behemoth, there's a billboard package with your name on it.</p>
            </CardContent>
          </Card>
        </div>
        
        <Alert>
          <Zap className="h-4 w-4" />
          <AlertTitle>But Wait, There's More!</AlertTitle>
          <AlertDescription>
            <ul className="list-disc list-inside">
              <li>Live Streaming Ads</li>
              <li>AI-Powered Insights</li>
              <li>Virtual Reality Previews</li>
            </ul>
          </AlertDescription>
        </Alert>
        
        <Card className="bg-green-50">
          <CardHeader>
            <CardTitle>The Cherry on Top? It's Eco-Friendly!</CardTitle>
          </CardHeader>
          <CardContent>
            <p>By streamlining the booking process and optimizing ad placements, we're reducing paper waste and energy consumption.</p>
          </CardContent>
        </Card>
        
        <div className="text-center mt-8">
          <h3 className="text-xl font-semibold mb-4">Ready to Join the Billboard Booking Revolution?</h3>
          <Badge variant="secondary" className="text-lg p-2">
            Book your first billboard now and get 10% off with code: EASYPEASY
          </Badge>
        </div>
      </div>
    </>
  );
};

export default EasyOnlineBillboardBooking;