import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const blogsData = [
  { id: 'billboard-advertising-india', title: 'Billboard Advertising in India' },
  { id: 'make-money-using-billboard', title: 'Make Money Using Billboards' },
  { id: 'booking-billboard-online-ncr', title: 'Booking Billboards Online in NCR' },
  { id: 'rent-billboard-space-ncr', title: 'Rent Billboard Space in NCR' },
  { id: 'digital-hoarding-rates-in-gurgaon', title: 'Digital Hoarding Rates in Gurgaon' },
  { id: 'easy-online-billboard-booking', title: 'You Won\'t Believe How Easy Online Billboard Booking Can Be!' },
  { id: 'passive-income-billboard', title: 'Passive income using billboard' },
  { id: 'make-money-billboard', title: 'Make money from billboard' },
  { id: 'boost-society-income-using-billboard', title: "Boost Your Society's Income: AdBoard Booking's Innovative Advertising Solution" },
  { id: 'frustration-to-freedom', title: "From Frustration to Freedom: A Billboard Owner's Journey" },
  { id: 'chaos-to-clarity', title: "From Chaos to Clarity: An Agency's Billboard Booking Revolution" },
];

const BlogsMap = () => {
  return (
    <Card className="w-full mx-auto">
      <CardHeader>
        <CardTitle>Blogs</CardTitle>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {blogsData.map((item, index) => (
            <li key={index}>
              <a 
                href={"/blogs/"+item.id} 
                className="text-blue-600 hover:underline"
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default BlogsMap;