import React, { ReactElement, useEffect, useState } from 'react';
import { apiFetch } from '@/hooks/fetch';
import { useFirebaseContext } from './FirebaseContext';

export interface APIResponse {
  config: Config
  user: User
  memberships: Membership[]
}

export type Config =  {
  apiUrl: string
  adminUrl: string
  pisignageUrl: string
  pisignageToken: string
  env: string
  gst: string
  cgst: string
  sgst: string
  paymentGateway: string
}|{
  apiUrl: string
  env: string
}

export interface User {
  stripe: Stripe
  refunds: any[]
  id: string
  uid: string
  email: string
  image: string
  phoneNumber: string
  displayName: string
  otherDetails: OtherDetails
  roles: any[]
  sharedBookingIds: string[]
  disclaimerAccepted: boolean
}

export interface Stripe {
  customerId: string
}

export interface OtherDetails {
  uid: string
  email: string
  emailVerified: boolean
  displayName: string
  photoURL: string
  phoneNumber: string
  disabled: boolean
  metadata: Metadata
  customClaims: CustomClaims
  tokensValidAfterTime: string
  providerData: ProviderDaum[]
}

export interface Metadata {
  lastSignInTime: string
  creationTime: string
  lastRefreshTime: string
}

export interface CustomClaims {
  roles: any[]
}

export interface ProviderDaum {
  uid: string
  displayName?: string
  email?: string
  photoURL?: string
  providerId: string
  phoneNumber?: string
}

export interface Membership {
  id: string
  createdAt: string
  userId: string
  workspaceId: string
  roleId: string
  workspace: Workspace
  role: Role
}

export interface Workspace {
  id: string
  type: string
  name: string
  key: string
  userIds: any[]
  createdAt: string
  updatedAt: string
}

export interface Role {
  id: string
  name: string
  permissions: string[]
  workspaceId: any
  createdAt: string
  updatedAt: string
}

export interface Data {
  config?:Config,
  flags:{
    paymentGateway: "razorpay"|"instamojo"|"stripe"
  }
  loading:boolean
  user?:User
}

const defaults:Data={
  loading:true,
  flags:{
    paymentGateway:"instamojo"
  }  
}

export const UserContext = React.createContext<Data>(defaults);

export const UserProvider = ({ children }: { children: ReactElement }) => {
  const {token } = useFirebaseContext();
  const [data,setData] = useState<Data>(defaults);
  const [loading, setLoading] = useState(false);

  useEffect(() => { 
    if(!token){
      return;
    } 
    setLoading(true);
    apiFetch(`/api/user`,{
      headers:{
        'Authorization':`Bearer ${token}`
      }
    })
      .then(res=>res.json())
      .then((res:APIResponse)=>{
        setData(prevData=>{
          return {
            ...prevData,
            config:res.config,
            user:res.user,
          }
        })
      }).finally(()=>{
        setLoading(false);
      });
  }, [token]);
 
  return <UserContext.Provider value={{
    config:{
      ...data.config,
      apiUrl:import.meta.env.VITE_PUBLIC_API_URL||"",
      env:import.meta.env.VITE_PUBLIC_NODE_ENV != 'development' ? 'production' : 'development'
    },
    loading,
    user:data.user,
    flags:{
      paymentGateway:"instamojo"
    },
  }}>
    {children}
  </UserContext.Provider>
}
