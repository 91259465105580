import { DATE_FORMAT } from "@/contains/contants";
import moment from "moment";
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading = "Screens in Gurugram",
  subHeading,
}) => {

  const location = useLocation();
  const params  = new URLSearchParams(location.search);
  const startDate = params.get('startDate')||new Date();
  const endDate = params.get('endDate')||new Date();
  return (
    <div className={`mb-6 lg:mb-6 ${className}`}>
      <h2 className="text-4xl font-semibold">{heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          {moment(startDate,DATE_FORMAT).format('DD/MMM')} - {moment(endDate,DATE_FORMAT).format('DD/MMM')}
        </span>
      )}
    </div>
  );
};

export default Heading2;
