import { Popover } from "@headlessui/react";
import { useCartContext } from "@/context/CartContext";
import { Badge } from "flowbite-react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  className?: string;
}

const CartDropdown: FC<Props> = ({ className = "" }) => {
  const {adBoards} = useCartContext()
  const open =true;
  const navigate = useNavigate();

  if(adBoards.length === 0) return null;

  return (
    <div className={className}>
      <Popover>
        <Popover.Button
          onClick={()=>navigate('/campaigns/new'+window.location.search,{
            replace: true
          })}
          className={` ${
            open ? "" : "text-opacity-90"
          } group p-3 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
        >
          {/* <span className="w-2 h-2 bg-blue-500 absolute top-2 right-2 rounded-full">1</span> */}
          {adBoards.length > 0 && (
            <Badge color="purple" size="sm" className="rounded-full absolute top-[-4px] right-[-4px]">{adBoards.length}</Badge>
          )}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
          </svg>
              Cart
        </Popover.Button>
      </Popover>
    </div>
  );
};

export default CartDropdown;
