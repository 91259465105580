import React from 'react'
import { useParams } from 'react-router-dom'
import BillboardAdvertisingIndia from './blogs/BillboardAdvertisingIndia';
import BillboardMoneyArticle from './blogs/MakeMoneyUsingBillboard';
import BillboardBookingNCRArticle from './blogs/BookingBillboardOnlineNCR';
import DigitalHoardingRatesGurgaonArticle from './blogs/BillboardAdvertisingGurgoan';
import { SectionGridFeatureAdboard } from '../PageHome/SectionGripFeatureAdboard';
import EasyOnlineBillboardBooking from './blogs/EasyBillboardBooking';
import BlogsMap from '../sitemap/BlogsMap';
import PassiveIncomeBillboard from './blogs/PassiveIncomeBillboard';
import BillboardMoneyMakingGuide from './blogs/MakeMoneyBillboard';
import BillboardForResidentials from './blogs/BillbaordForResidentials';
import FrustrationToFreedom from './blogs/FrustrationToFreedom';
import ChaosToClarity from './blogs/ChaosToClarity';

export default function Blogs() {
  const {id} = useParams();
  
  return <>
    <div className="container relative">
      <div className='py-8'>
        {id == 'billboard-advertising-india' && <BillboardAdvertisingIndia />}
        {id == 'make-money-using-billboard' && <BillboardMoneyArticle />}
        {id == 'booking-billboard-online-ncr' && <BillboardBookingNCRArticle />}
        {id == 'rent-billboard-space-ncr' && <BillboardBookingNCRArticle />}
        {id == 'digital-hoarding-rates-in-gurgaon' && <DigitalHoardingRatesGurgaonArticle />}
        {id == 'easy-online-billboard-booking' && <EasyOnlineBillboardBooking />}
        {id == 'passive-income-billboard' && <PassiveIncomeBillboard/>}
        {id == 'make-money-billboard' && <BillboardMoneyMakingGuide />}
        {id == 'get-paid-for-billboard' && <BillboardMoneyMakingGuide />}
        {id == "boost-society-income-using-billboard" && <BillboardForResidentials />}
        {id == "frustration-to-freedom" && <FrustrationToFreedom />}
        {id == "chaos-to-clarity" && <ChaosToClarity />}
        {!id && <div><BlogsMap/></div>}
      </div>
      <div className='mb-10'>
        <SectionGridFeatureAdboard />
      </div>
    </div>
  </>
}
