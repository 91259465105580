import BgGlassmorphism from '@/components/BgGlassmorphism/BgGlassmorphism'

import Heading from '@/components/Heading/Heading'
import useUserContext from '@/hooks/useUserContext'
import Loader from '@/shared/FullScreenLoader/Loading'
import { Helmet } from 'react-helmet'
import SectionGridFilterCard from './SectionGridFilterCard'
import ButtonPrimary from '@/shared/Button/ButtonPrimary'
import { Link } from 'react-router-dom'

export default function Campaigns({className}: {className?: string}) {
  const {user} = useUserContext();
  
  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Campaigns | Adboard Booking</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative py-4">
        <div className='flex justify-between items-center' > 
          <Heading desc='You can manage your campaigns here'>Campaigns</Heading>
          <div>
            <Link to="/campaigns/new">
              <ButtonPrimary>
            Start a new campaign
              </ButtonPrimary>
            </Link>
          </div>

        </div>
        {!user?.uid && <Loader/>}
        <div>
          <SectionGridFilterCard/>
        </div>
      </div>
    </div>
  )
}
