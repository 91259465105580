import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";

const BillboardMoneyMakingGuide = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">How to Make Money with Billboards</h1>
      
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>The Billboard Business Opportunity</CardTitle>
          <CardDescription>Discover why billboards can be a lucrative investment</CardDescription>
        </CardHeader>
        <CardContent>
          <p>Billboards have been a reliable source of income for entrepreneurs for over half a century. The outdoor advertising industry offers several advantages:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>High profit margins (typically 50-100%)</li>
            <li>Relatively simple business model</li>
            <li>Potential for passive income</li>
            <li>Scalability from small to large operations</li>
          </ul>
        </CardContent>
      </Card>

      <Accordion type="single" collapsible className="mb-6">
        <AccordionItem value="item-1">
          <AccordionTrigger>Key Factors for Success</AccordionTrigger>
          <AccordionContent>
            <ol className="list-decimal pl-6">
              <li>Understanding billboard regulations and zoning laws</li>
              <li>Identifying high-traffic locations for maximum visibility</li>
              <li>Negotiating favorable land leases</li>
              <li>Building relationships with advertisers</li>
              <li>Maintaining and upgrading billboard structures</li>
            </ol>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Revenue Streams in the Billboard Business</CardTitle>
        </CardHeader>
        <CardContent>
          <p>There are multiple ways to generate income with billboards:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>Static billboards along highways and streets</li>
            <li>Digital billboards with rotating ads</li>
            <li>Mobile billboards on vehicles</li>
            <li>Transit advertising (bus stops, train stations)</li>
            <li>Indoor advertising in malls and other high-traffic areas</li>
          </ul>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Getting Started</CardTitle>
        </CardHeader>
        <CardContent>
          <p>To begin your billboard business:</p>
          <ol className="list-decimal pl-6 mt-2">
            <li>Research local regulations and available locations</li>
            <li>Secure necessary permits and land leases</li>
            <li>Invest in billboard construction or acquisition</li>
            <li>Develop a marketing strategy to attract advertisers</li>
            <li>Consider starting small and scaling up as you gain experience</li>
          </ol>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Maximizing Profitability</CardTitle>
        </CardHeader>
        <CardContent>
          <p>To increase your billboard revenue:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>Focus on high-demand areas with strong traffic flow</li>
            <li>Offer competitive rates to attract a diverse range of advertisers</li>
            <li>Maintain your billboards to ensure they remain attractive to clients</li>
            <li>Consider upgrading to digital billboards for higher revenue potential</li>
            <li>Explore partnerships with local businesses for long-term contracts</li>
          </ul>
        </CardContent>
      </Card>

      <Button className="w-full">Learn More About Billboard Investing</Button>
    </div>
  );
};

export default BillboardMoneyMakingGuide;