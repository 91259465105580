import BackgroundSection from "@/components/BackgroundSection/BackgroundSection";
import Box from "@/components/Box";
import SectionSliderNewCategories from "@/components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "@/components/SectionSubscribe2/SectionSubscribe2";
import { SocialLogin } from "@/components/SocialLogin";
import StepIndicator from "@/components/StepIndicator/StepIndicator";
import { BookingStatus, PaymentStatus, TemplateIds } from "@/generated/zeus";
import useUserContext from "@/hooks/useUserContext";
import { mutation } from "@/hooks/useZeus";
import StatusBadge from "@/shared/Badge/StatusBadge";
import ButtonSecondary from "@/shared/Button/ButtonSecondary";
import Loader from "@/shared/FullScreenLoader/Loading";
import moment from "moment";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import MediaLibraryPicker from "../MediaAssetsPage/MediaLibraryPicker";
import BookingChangelog from "./BookingChangelog";
import { useBookingDetails } from "./useBookingDetails";
import { GST } from "@/contains/contants";
import { Dialog } from "@/components/Dialog/Dialog";
import PaymentDialog from "./PaymentDialog";
import { PriceSummary } from "./PriceSummary";

export interface ListingCarDetailPageProps {
  className?: string;
}

export function BookingDetails() {

  return <>
    <ListingCarDetailPage />
  </>
}

const ListingCarDetailPage: FC<ListingCarDetailPageProps> = ({
  className = "",
}) => {
  const { bookingDetails, loading, refetch } = useBookingDetails();
  const booking = bookingDetails?.booking;
  const priceSummary = booking?.priceSummary;
  const { adBoard, payment, selectedDates } = bookingDetails?.booking || {}
  const [bookingUpdateCounter, setBookingUpdateCounter] = useState(0);
  const { user } = useUserContext()

  const renderAdboardDetails = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <StatusBadge name={bookingDetails?.booking?.bookingStatus.toString()} />
          {/* <LikeSaveBtns /> */}
        </div>

        {/* 2 */}
        <a target="_blank" href={"/adboards/" + adBoard?.id+window.location.search}>
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {adBoard?.title}
          </h2>
        </a>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {/* <StartRating /> */}
          {/* <span>·</span> */}
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {adBoard?.city}</span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div>
          <span className="text-2xl font-semibold">
            Adboard contact details
          </span>
          <div className="flex flex-col mt-2 space-y-4 ">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Name</span>
              <span>{booking?.adBoard.offlineBookingsContact?.displayName}</span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Email</span>
              <span>{booking?.adBoard.offlineBookingsContact?.email}</span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Phone</span>
              <span>{booking?.adBoard.offlineBookingsContact?.phoneNumber}</span>
            </div>
           
          </div>
        </div>
      </div>
    );
  };

  const proofsSection = () => {
    if (!bookingDetails?.booking?.proofs?.length) {
      return
    }
    return (
      <Box title="Proofs">
        <div className="Sirv">
          {bookingDetails?.booking?.proofs?.map((item) => (
            <div data-src={item.url}></div>
          ))}
        </div> 
      </Box>
    );
  };

  const renderHistory = () => {
    return (
      <Box title="Change history" collapsible collapsed>
        
        <div className="relative overflow-x-auto mt-4">
          {booking?.id && <BookingChangelog counter={bookingUpdateCounter} bookingId={booking?.id} />}
        </div>

      </Box>
    );
  };

  const renderPaymentDetails = () => {
    return (
      <div className="listingSection__wrap mt-4 lg:mt-0">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-2xl font-semibold">
            Payment details
          </span>

        </div>

        {booking?.payment ?
       
          <div className="flex flex-col space-y-4 ">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Subtotal</span>
              <span>{payment?.totalDetails?.subtotal}</span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>GST({GST})</span>
              <span>{payment?.totalDetails?.tax}</span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Discount</span>
              <span>{payment?.totalDetails?.discount}</span>
            </div>

            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex justify-between font-semibold">
              <span>Total</span>
              <span>{payment?.totalDetails?.total}</span>
            </div>
          </div>
          : <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
      
            <span>Payment not done yet</span>
            <span>₹0</span>
          </div>}

      </div>
    );
  };

  async function triggerMail(){
    if(booking?.id){
      await mutation({
        sendMail:[{
          bookingId:booking?.id,
          templateId:TemplateIds.ASSET_UPDATED,
          to:[]
        },{
          success:true
        }]
      })
    }
  }

  async function handleFileSelect(file: { id: string, name: string, url: string }) {

    if (!file.id) {
      toast.error('File url is not present.');
      return;
    }

    if (moment(booking?.selectedDates.endDate).isBefore(moment(), 'date')) {
      toast.error('Booking has already ended.');
      return;
    }

    const answer = confirm("Are you sure you want to change the asset? (Y/N)")

    if (answer && booking?.id) {

      const { updateOneBooking } = await mutation({
        updateOneBooking: [{
          where: {
            id: booking?.id
          },
          data: {
            bookingStatus: BookingStatus.PENDING_APPROVAL,
            assets: {
              set: [{
                id: file.id,
              }]
            }
          }
        }, {
          id: true,
        }],
        createOneAudit: [{
          data: {
            action: "ASSET_UPDATED",
            entity: {
              type: "BOOKING",
              id: booking?.id
            },
            message: `Asset changed to ${file.name} and booking status changed to pending approval.`,
            user: {
              connect: {
                uid: user?.uid
              }
            },
            updates: JSON.stringify({ url: file.url })
          }
        }, {
          id: true
        }]

      })

      if (!updateOneBooking?.id) {
        toast.error('Something went wrong. Please try again later.');
        return;
      }

      setBookingUpdateCounter(bookingUpdateCounter + 1);
      refetch();

      triggerMail()
    }
  }

  const renderDeployedAsset = () => {

    const item = booking?.assets?.[0];

    return (
      <div className="listingSection__wrap lg:shadow-xl max-w-[500px]">
        <span className="text-2xl font-semibold gap-2 mt-4 lg:mt-0 flex items-center">
          Current asset <StatusBadge name={item?.status}></StatusBadge>
        </span>
        <div className="relative w-full h-full rounded-2xl overflow-hidden will-change-transform">
          <div key={item?.url} className="Sirv" data-src={item?.url}></div>
        </div>

        <MediaLibraryPicker onCardSelect={handleFileSelect}>
          <ButtonSecondary className='mt-4'>Change asset</ButtonSecondary>
        </MediaLibraryPicker>

      </div>
    );
  };

  const renderSidebarDetail = () => {
    return (
      <div className="listingSection__wrap">
        <div className="flex justify-between items-center">
          <span className="text-2xl font-semibold block mt-4 lg:mt-0">
            Booking details
          </span>
        </div>

        <div className="flex flex-col space-y-4 ">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Booked on</span>
            <span>{moment(booking?.bookingDate).format('LLL')}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Booking start date</span>
            <span>{moment(selectedDates?.startDate as string).format('DD MMM YYYY')}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Booking end date</span>
            <span>{moment(selectedDates?.endDate).format('DD MMM YYYY')}</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Booking code</span>
            <span>{booking?.bookingCode}</span>
          </div>
        </div>
      </div>
    );
  };

  if (!user) {
    return <div className="container mt-8">
      <h1 className="py-40">
        <SocialLogin />
      </h1>
    </div>
  }
  
  if (loading || loading == undefined) {
    return <Loader fullScreen>
      <div>Fetching booking...</div>
    </Loader>
  }

  if (!loading && !booking?.id) {
    return <div className="container mt-8">
      <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold pb-40">
        Booking not found
      </h1>
    </div>
  }

  return (
    <div
      className={`ListingDetailPage nc-ListingCarDetailPage ${className}`}
      data-nc-id="ListingCarDetailPage"
    >

      <div className="container mt-8">

        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          Your booking at {adBoard?.title}
        </h1>
        <div className="listingSection__wrap mt-8 lg:shadow-md">
          <StepIndicator booking={booking} />
        </div>
        {booking?.bookingStatus == BookingStatus.APPROVED && booking.paymentStatus!= PaymentStatus.PAID && 
        <div className="p-4 flex mt-6 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
          <div className="flex justify-between w-full items-center">
            <span>
              <span className="font-medium">Congratulations!</span> Your booking has been approved, Please proceed for payment.
            </span>
            <Dialog title="Confirm payment">
              <Dialog.Trigger>
                <ButtonSecondary className="">Proceed to payment</ButtonSecondary>
              </Dialog.Trigger>
              <Dialog.Body>
                <PaymentDialog bookingId={booking.id} priceSummary={priceSummary}/>
              </Dialog.Body>
            </Dialog>
          </div>
        </div>
        }
      </div>

      <main className="container relative z-10 mt-6 grid lg:grid-cols-10 gap-8">
        <div className="col-span-7 flex flex-col gap-8">
          {/* {renderDeployedAsset()} */}
          {renderAdboardDetails()}
          {renderSidebarDetail()}
          {/* <Box title="Price summary"> */}
          <PriceSummary priceSummary={{
            rentPerDay: priceSummary?.rentPerDay||0,
            days: priceSummary?.days||0,
            totalRent: priceSummary?.totalRent||0,
            payableRent: priceSummary?.payableRent||0,
            serviceCharge: priceSummary?.serviceCharge||0,
          }} />
          {/* </Box> */}
          
          {renderPaymentDetails()}
          
          <div className="block lg:hidden">
            {renderDeployedAsset()}
          </div>
          {proofsSection()}
          {renderHistory()}

        </div>
        <div className="col-span-3">
          <div className="hidden lg:flex gap-4 flex-col sticky top-28">
            {renderDeployedAsset()}
          </div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {/* <MobileFooterSticky sta/> */}

      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32">
        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of adbords"
            subHeading="Explore adboards based on 10 types of signages"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingCarDetailPage"
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div>
    </div>
  );
};

export default ListingCarDetailPage;
