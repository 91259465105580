import BgGlassmorphism from "@/components/BgGlassmorphism/BgGlassmorphism";
import Box from "@/components/Box";

import Heading from "@/components/Heading/Heading";
import { DateRage } from "@/components/HeroSearchForm/StaySearchForm";
import StayDatesRangeInput from "@/components/HeroSearchForm2/StayDatesRangeInput";
import { DATE_FORMAT, GST } from "@/contains/contants";
import { useCartContext } from "@/context/CartContext";
import useUserContext from "@/hooks/useUserContext";
import { mutation, query, useTypedLazyQuery } from "@/hooks/useZeus";
import Avatar from "@/shared/Avatar/Avatar";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import { TrashIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {  Link, useSearchParams } from "react-router-dom";
import CampaignDetailBox from "./CampaignDetailBox";
import { BookingStatus, ContentApprovalStatus, DeploymentStatus, PaymentStatus } from "@/generated/zeus";
import { toast } from "react-toastify";
import { useGateway } from "../CheckOutPage/useGateway";
import { useFileUploader } from "@/hooks/useFileUploader";
import Loader from "@/shared/FullScreenLoader/Loading";
import {SocialLogin} from "@/components/SocialLogin";
import ButtonSecondary from "@/shared/Button/ButtonSecondary";
import { fireEvent, fireEventOnce, mapAdboardToGtagItem } from "@/lib/utils";

export interface CheckOutPageProps {
  className?: string;
  orderId?: string
}

const CampaignPage: FC<CheckOutPageProps> = ({ }) => {
  const { adBoards, removeFromCart,clearCart } = useCartContext()
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startDate')
  const endData = searchParams.get('endDate')
  const canceled = searchParams.get('canceled')
  const campaignId = searchParams.get('campaignId');

  const [getAdboards,{ data: adBoardsData }] = useTypedLazyQuery({
    adBoards: [{
      where: {
        id: {
          in: adBoards.map(item => item.id)
        }
      }
    }, {
      id: true,
      address: true,
      galleryImgs: true,
      title: true,
      city: true,
      pricePerDay: true,
    }]
  },{
    fetchPolicy:'network-only'
  })

  useEffect(()=>{
    if(adBoards.length){
      getAdboards();
    }
  },[adBoards])

  useEffect(()=>{
    if(canceled && campaignId){
      mutation({
        deleteOneCampaign:[{
          where:{
            id:campaignId
          }
        },{
          id:true
        }]
      })
    }
  },[canceled,campaignId])
  
  const { user } = useUserContext();
  const { uploadFile } = useFileUploader()
  const [formData, setFormData] = useState<Record<string, any>>({
    files:[]
  });
  const today = moment();
  const todayString = today.format(DATE_FORMAT);
  const { initiateCampaignBooking } = useGateway();
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: startDate ? moment(startDate, DATE_FORMAT) : today,
    endDate: endData ? moment(endData, DATE_FORMAT) : today
  })
  const [loading,setLoading] = useState(false);
  const rentPerDay = adBoardsData?.adBoards.map(item => item.pricePerDay).reduce((a, b) => a + b, 0) || 0;
  let days = 1 + (selectedDate.endDate?.diff(selectedDate.startDate, 'days') || 0);
  if(days<=0){
    days = 0;
  }
  const totalRent = rentPerDay * days;
  const serviceCharge = totalRent * 0.18;
  const payableRent = totalRent + serviceCharge;

  const order = {
    priceSummary: {
      rentPerDay,
      days,
      totalRent,
      serviceCharge,
      payableRent
    }
  }

  useEffect(()=>{
    if(adBoardsData && selectedDate.startDate && selectedDate.endDate){
      const {startDate,endDate} = selectedDate;
      fireEventOnce('view_cart',{
        currency: "INR",
        value: payableRent,
        items: adBoardsData.adBoards.map(item=>{
          return mapAdboardToGtagItem(item,{
            startDate:startDate.toISOString(),
            endDate:endDate.toISOString()
          })
        })
      })
    }
    
  },[])

  async function handleConfirm() {
    const {startDate,endDate} = selectedDate;
    try {

      if(!startDate || !endDate){
        toast.error('Please select a date range to continue')
        return;
      }

      if(!adBoardsData?.adBoards.length){
        toast.error('Please add at least one adboard to continue')
        return;
      }

      setLoading(true);
      if (!user?.displayName) {
        toast.error('Please update your profile to continue')
        return;
      }
      if (!formData.name) {
        toast.error('Please enter your campaign name')
        return;
      }
      if (!formData.industry) {
        toast.error('Please select your industry')
        return;
      }

      const userId = user?.uid;
      const dbUser = await query({
        user: [{
          where: {
            uid: userId
          }
        }, {
          id: true
        }]
      })
      if (!userId || !dbUser.user) {
        toast.error('Please login to continue')
        return;
      }

      const assets = await Promise.all(formData.files?.map(async file => {
        return uploadFile(file).then(({ id }) => {
          return id
        })
      }));

      fireEvent('begin_checkout',{
        currency: "INR",
        value: payableRent,
        items: adBoardsData.adBoards.map(item=>{
          return mapAdboardToGtagItem(item,{
            startDate:startDate.toISOString(),
            endDate:endDate.toISOString()
          })
        })
      })      

      const { createOneCampaign } = await mutation({
        createOneCampaign: [{
          data: {
            paymentStatus: PaymentStatus.PENDING,
            origin: window.location.origin,
            selectedDates: {
              startDate: moment(searchParams.get('startDate'), DATE_FORMAT),
              endDate: moment(searchParams.get('endDate'), DATE_FORMAT)
            },
            name: formData.name,
            user: {
              connect: {
                uid: user?.uid
              }
            },
            businessCategory: {
              connect: {
                id: formData.industry
              }
            },
            bookings: {
              createMany: {
                data: adBoards.map(item => {
                  return ({
                    deploymentStatus: DeploymentStatus.NOT_STARTED,
                    contentApprovalStatus: ContentApprovalStatus.PENDING_REVIEW,
                    assetsIDs: assets,
                    adBoardId: item.id,
                    bookingCode: Math.random().toString(36).substring(7),
                    bookingDetails: {
                      email: user?.email || "",
                      name: user?.displayName || "",
                      phone: user?.phoneNumber
                    },

                    bookingStatus: BookingStatus.PAYMENT_PENDING,
                    selectedDates: {
                      startDate: moment(searchParams.get('startDate'), DATE_FORMAT),
                      endDate: moment(searchParams.get('endDate'), DATE_FORMAT)
                    },
                    userId: dbUser.user?.id as string
                  })
                })
              }
            }
          }
        }, {
          id: true
        }]
      });
      try{
        await initiateCampaignBooking(createOneCampaign?.id)
      }catch(e){
        await mutation({
          deleteOneCampaign: [{
            where: {
              id: createOneCampaign?.id
            }
          }, {
            id: true
          }]
        })
      }
    } catch (e) {
      
    } finally{
      setLoading(false);
    }
  }

  function bookNow({ isSidebar }: { isSidebar?: boolean } = {}) {
    return <>
      <div className="mt-6">
        <p className='text-xs mt-4'>
          By clicking the button below, I agree to the <a className="text-blue-800" href="https://www.adboardbooking.com/cancellation.html" target="_blank">Adboard Booking Rules, and Refund Policy</a> and that Adboard Booking can charge my payment method if I’m responsible for damage. I agree to pay the total amount shown if the Host accepts my booking request.
        </p>

        <div className="pt-8">
          <>
            {!user && !isSidebar && <div >
              <h2 className="text-2xl lg:text-2xl font-semibold">
                Log in or sign up to book
              </h2>
              <div className="mt-4">
                {loading ? <Loader /> : <SocialLogin />}
              </div>
            </div>}
            {user &&
              <>
                <ButtonPrimary onClick={() => handleConfirm()} loading={loading} type='submit' disabled={!user}>Confirm and pay</ButtonPrimary> &nbsp; <span className='text-sm'><br />(Only credit and debit cards accepted)</span>
              </>
            }
          </>
        </div>

      </div>
    </>

  }

  function handleDateChange(date: DateRage) {
    setSelectedDate(date)
    setSearchParams({
      ...Object.entries(searchParams),
      startDate: date.startDate?.format(DATE_FORMAT) || todayString,
      endDate: date.endDate?.format(DATE_FORMAT) || todayString
    })
  }

  function handleChange(args: Record<string, any>) {
    setFormData({
      ...formData,
      ...args
    })
  }

  type AdboardData = NonNullable<typeof adBoardsData>['adBoards'][0]

  function handleRemoveCart(item:AdboardData){
    removeFromCart(item.id)
    const {startDate,endDate} = selectedDate;
    if(startDate && endDate){
      fireEvent('remove_from_cart',{
        currency: "INR",
        value: item.pricePerDay,
        items: [
          mapAdboardToGtagItem(item,{
            startDate:startDate.toISOString(),
            endDate:endDate.toISOString(),
          })
        ]
      })
    }
    
  }

  return (
    <div className={`overflow-hidden relative`}>
      <Helmet>
        <title>Create Campaign | AdBoard Booking</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container relative py-4">
        <Heading desc='You can manage your campaigns here'>Create your campaign</Heading>

        <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-8">
          <div className="col-span-2 flex flex-col gap-4 ">
            <Box title="Adboards in cart" buttons={<>
              <ButtonSecondary onClick={()=>{clearCart()}}>
                Clear
              </ButtonSecondary>
            </>}>
              {adBoards.length==0 && <>
                <div className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                  Please add at least one adboard to continue
                </div>
              </>}
              <div className="mt-2">
                {adBoardsData?.adBoards.map((item, index) => {
                  return <Link
                    key={index}
                    to={"/adboards/"+item.id+window.location.search}
                    className="flex p-2 gap-2 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 relative items-center"
                  > 
                    <div className="w-8 h-8 sm:w-12 sm:h-12">

                      <Avatar sizeClass="w-8 h-8 sm:w-12 sm:h-12" imgUrl={item.galleryImgs[0]} />
                    </div>
                    <div className="ml-3 sm:ml-4 space-y-1 justify-between flex-1">
                      <p className="text-sm font-medium text-gray-900 dark:text-gray-200">
                        {item.title}
                      </p>
                      <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                        {item.address}
                      </p>
                      <p className="text-xs text-gray-400 dark:text-gray-400">
                        {item.pricePerDay}/day
                      </p>
                    </div>
                    <div className="w-6 text-gray-700">
                      <TrashIcon onClick={()=>handleRemoveCart(item)} />
                    </div>
                    
                  </Link>
                })}
              </div>
            </Box>
            <Box title="Campaign details">
              <CampaignDetailBox onChange={handleChange} />
            </Box> 
            
            <div className="hidden lg:block">
              {adBoards.length > 0 ?
                <>
                  <div className='mt-8'>
                    <h2 className='text-lg font-semibold'>Cancellation policy</h2>
                    <div className='text-sm'>
              This reservation is non-refundable. <Link target='_blank' to="/cancellation.html">Learn more</Link>
                    </div>
                  </div>
      
                  {bookNow()}
                </>
                :
                <></>
              }
            </div>
            
          </div>
          <div className="flex flex-col gap-4">
            
            <Box title="Pricing details">
              <form className="flex my-4 flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
                <StayDatesRangeInput
                  // blockedDates={blockedDates}
                  wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
                  onChange={handleDateChange}
                  fieldClassName="p-3"
                  defaultValue={selectedDate}
                  anchorDirection={"right"}
                  className="nc-ListingStayDetailPage__stayDatesRangeInput flex-1"
                />
              </form>

              <div className="flex flex-col gap-2 mt-2">
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>₹{order?.priceSummary.rentPerDay} x {order?.priceSummary.days} day</span>
                  <span>₹{order?.priceSummary.totalRent}</span>
                </div>

                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>GST (@{GST})</span>
                  <span>₹{order?.priceSummary.serviceCharge}</span>
                </div>

                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex justify-between font-semibold">
                  <span>Total</span>
                  <span>₹{order?.priceSummary.payableRent}</span>
                </div>
              </div>
              <div className="">
                {adBoards.length > 0 ?
                  <div className="pt-8 lg:hidden">
                    {bookNow({ isSidebar: true })}
                  </div>
                  :
                  <></>
                }
              </div>
            </Box>
          </div>
        </div>
      </div>

    </div>

  );
};

export default CampaignPage;
