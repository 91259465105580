import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { AdboardFragment } from '@/hooks/useAdboards'
import { TrashIcon } from '@heroicons/react/24/solid'
import { Check, FileWarning } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import FilePreview from './FilePreview'

const aspectRatioMap = {
  "Beyond long vertical": {
    "min": 0,
    "max": 0.43
  },
  "Long Vertical": {
    "min": 0.43,
    "max": 0.56
  },
  "Portrait": {
    "min": 0.56,
    "max": 0.8
  },
  "Square": {
    "min": 0.8,
    "max": 1.25
  },
  "Wide": {
    "min": 1.25,
    "max": 2.33
  },
  "Ultrawide": {
    "min": 2.33,
    "max": 3.6
  },
  "Beyond ultrawide": {
    "min": 3.6,
    "max": Infinity
  }
}

type AspectType = keyof typeof aspectRatioMap;

function getTypeBasedOnAspectRatio(aspectRatio): AspectType|undefined { 
  for (const [key, value] of Object.entries(aspectRatioMap)) {
    if(aspectRatio >= value.min && aspectRatio < value.max){
      return key as AspectType
    }
  }
}

export default function CreativesBox({
  selectedAdboards,
  creatives,
  setCreatives,
}: {
  selectedAdboards?:  AdboardFragment[],
  creatives: {
    file:File,
    aspectType?: AspectType
    resolution:{
      width:number,
      height:number
    }
  }[],
  setCreatives?: (args:{
    file,
    aspectType?: AspectType
    resolution:{
      width:number,
      height:number
    }
  }[])=>void,
}) {
  const [tableData, setTableData] = useState<{
    resolution: string,
    players: number,
    aspectRatio: string
  }[]>([])

  const [creativeTypes,setCreativeTypes] = React.useState<AspectType[]>([]);

  useEffect(() => {

    const creativeAspectTypes = new Set<AspectType>();

    if (selectedAdboards) {
      // setSelectedAdboards(data.adBoards.r)
      const resolutionMap = selectedAdboards.map(item=>{
        return item.players.map(item=>item.resolution) as {width:number,height:number}[]
      }).flat().filter(item=>item).map(item => {
        return {
          resolution: item.width + ' x ' + item.height,
          aspectRatio: (item.width || 0) / (item.height || 1),
        }
      })

      const resolutionCoutner = resolutionMap.reduce((acc: any, item: any) => {
        if (!acc[item.resolution]) {
          acc[item.resolution] = {
            counter: 0,
            aspectRatio: item.aspectRatio
          }
        }
        acc[item.resolution].counter++;
        return acc;
      }, {});

      const tableData = Object.keys(resolutionCoutner).map((key) => {
        creativeAspectTypes.add(getTypeBasedOnAspectRatio(resolutionCoutner[key].aspectRatio) as AspectType);
        return {
          resolution: key,
          aspectRatio: resolutionCoutner[key].aspectRatio.toFixed(2),
          players: resolutionCoutner[key].counter,
        };
      }).sort((a, b) => {
        const diff = b.players - a.players
        if (diff === 0) {
          return b.aspectRatio - a.aspectRatio
        } else {
          return diff
        }
      })

      setTableData(tableData)
      setCreativeTypes(Array.from(creativeAspectTypes))

    }

  }, [selectedAdboards])

  function getVideoResolution(file){
    const url = URL.createObjectURL(file);
    const $video = document.createElement("video");
    $video.src = url;
    return new Promise<{
      width:number,
      height:number
    }>((resolve) => { 
      $video.addEventListener("loadedmetadata", function () {
        resolve({
          width: this.videoWidth,
          height: this.videoHeight
        });
      });
    })
   
  }

  const getHeightAndWidthFromDataUrl = dataURL => new Promise<{
    width:number,
    height:number
  }>(resolve => {
    const img = new Image()
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      })
    }
    img.src = dataURL
  })
  
  async function handleChange(e,targetAspectType?:AspectType) {
    const file:File = e.target.files[0]
    // Get the data URL of the image as a string
    const fileAsDataURL = window.URL.createObjectURL(file)
    let dimensions:{
      width:number,
      height:number
    };
    if(file.type.startsWith("image")){
      dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL)
    }else{
      dimensions = await getVideoResolution(file)
    }
    let aspectType = getTypeBasedOnAspectRatio(dimensions.width/dimensions.height)
    if(aspectType != targetAspectType){
      const sure = window.confirm(`The aspect ratio of the selected file is ${aspectType}. Are you sure you want to upload it as ${targetAspectType}?`);
      if(sure){
        aspectType = targetAspectType
      }  
    }

    setCreatives?.([...creatives,{
      file,
      aspectType,
      resolution:{
        width:dimensions.width,
        height:dimensions.height
      }
    }])
   
    e.target.value=""
  }

  return (
    <>
      <label className="block text-neutral-500 dark:text-neutral-400 text-sm font-normal">
        Below are the resolutions of the selected players. Creatives will be mapped automatically based on closest aspect ratio.
      </label>
      {tableData.length > 0 &&
        <div className="">
          <Table className=''>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]">Resolution</TableHead>
                <TableHead>Players</TableHead>
                <TableHead>Aspect ratio</TableHead>
                <TableHead>Creative mapped</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className=''>
              {tableData.sort((a,b)=>Number(a.aspectRatio)-Number(b.aspectRatio)).map((item) => (
                <TableRow key="user1">
                  <TableCell>{item.resolution}</TableCell>
                  <TableCell>{item.players}</TableCell>
                  <TableCell>{item.aspectRatio}:1 ({getTypeBasedOnAspectRatio(item.aspectRatio)})</TableCell>
                  <TableCell>
                    {creatives.some(creative=>{
                      const creativeAspectType = creative.aspectType;
                      const itemAspectType = getTypeBasedOnAspectRatio(Number(item.aspectRatio))
                      return creativeAspectType == itemAspectType
                    })?<Check className='text-green-700'></Check>
                      : <>
                        <label htmlFor="file-upload" className="cursor-pointer flex gap-2">
                          <FileWarning className='text-orange-400'></FileWarning>
                        </label>
                        <input onChange={(e)=>handleChange(e,getTypeBasedOnAspectRatio(Number(item.aspectRatio)))} type="file" accept='image/*, video/*' id="file-upload" className='hidden'/>
                      </>}
                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      }

      <div className='mt-4'>
        <div className="mb-2">
          <div className='text-lg font-bold'>Uploaded creatives</div>
          <div className='text-sm font-regular'>Creatives will be mapped to players automatically based on closest aspect ratio</div>
        </div>
        <Table className=''>
          <TableHeader>
            <TableRow>
              <TableHead>Aspect type</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Resolution</TableHead>
              <TableHead>Aspect ratio</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className=''>
            
            {creativeTypes.map((type) => {
              const item = creatives.find(item=>item.aspectType == type);
            
              return (
                <TableRow key="user1">
                  <TableCell>{type}</TableCell>
                  {item ? <>
                    <TableCell><FilePreview file={item.file}></FilePreview></TableCell>
                    {/* <TableCell>{item.file.name}</TableCell> */}
                    <TableCell>{item.resolution.width} x {item.resolution.height}</TableCell>
                    <TableCell>{Number(item.resolution.width/item.resolution.height).toFixed(2)}:1 </TableCell>
                    {setCreatives &&
                      <TableCell><TrashIcon className='text-red-700' width={'20'} onClick={() => {
                        setCreatives?.(creatives.filter(creative => creative.file !== item.file))
                      }} /></TableCell>
                    }
                  </>:<TableCell colSpan={3}>
                    <div className='py-6'>
                      <label htmlFor={"file-upload-"+type} className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 rounded-3xl">
              Add creative
                      </label>
                      <input onChange={(e)=>handleChange(e,type)} type="file" accept='image/*, video/*' id={"file-upload-"+type} className='hidden'/>
                      <div id={"file-name"+type} className="mt-2 text-sm text-gray-600 italic"></div>
                    </div>
                  </TableCell>}
                  
                </TableRow>
              )
              
            })}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
