import MapMarker from "@/components/AnyReactComponent/MapMarker"
import BgGlassmorphism from '@/components/BgGlassmorphism/BgGlassmorphism'
import Box from '@/components/Box'
import { DialogBody } from "@/components/Dialog/Dialog"
import Heading from '@/components/Heading/Heading'
import { DateRage } from '@/components/HeroSearchForm/StaySearchForm'
import { SocialLogin } from "@/components/SocialLogin"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { FEES, FEES_AFTER_DISCOUNT, GMAP_API_KEY, GST, GSTFactor } from '@/contains/contants'
import { useAppContext } from '@/context/AppContext'
import { useCartContext } from "@/context/CartContext"
import { useFirebaseContext } from "@/context/FirebaseContext"
import { AdBoardStatus, BookingStatus, ContentApprovalStatus, DeploymentStatus, PaymentStatus, WorkspaceType } from "@/generated/zeus"
import { adboardFragment, AdboardFragment } from '@/hooks/useAdboards'
import { useFileUploader } from "@/hooks/useFileUploader"
import useUserContext from '@/hooks/useUserContext'
import { mutation, useTypedLazyQuery } from "@/hooks/useZeus"
import { fireEvent, formatPrice, titleToUrl } from "@/lib/utils"
import ButtonPrimary from "@/shared/Button/ButtonPrimary"
import ButtonSecondary from '@/shared/Button/ButtonSecondary'
import ButtonClose from '@/shared/ButtonClose/ButtonClose'
import Loader from '@/shared/FullScreenLoader/Loading'
import Input from '@/shared/Input/Input'
import NcImage from "@/shared/NcImage/NcImage"
import Select from '@/shared/Select/Select'
import Textarea from '@/shared/Textarea/Textarea'
import { TrashIcon } from '@heroicons/react/24/solid'
import GoogleMapReact from "google-map-react"
import moment from "moment"
import { useEffect, useRef, useState } from 'react'
import { DayPickerRangeController, FocusedInputShape } from "react-dates"
import { Helmet } from 'react-helmet'
import { useForm } from "react-hook-form"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import CampaignSummary from "./CampaignSummary"
import CreativesBox from "./CreativesBox"
import useWindowSize from "@/hooks/useWindowResize"

type CampaignForm = {
  name: string
  description: string
  businessCategoryId: string,
  user:{
    name:string
    email:string
    phoneNumber:string
  }
}

export default function CampaignNew({
  className,
}: {
    className?: string
}) {
  const [searchParams] = useSearchParams();
  const {uploadFile} = useFileUploader()
  const {adBoards:cartAdboards,clearCart} = useCartContext();
  const [currentHoveredAdboard, setCurrentHoveredAdboard] = useState<AdboardFragment | undefined>();
  const {loading:authLoading,user:fbUser} = useFirebaseContext()
  const mapRef = useRef<any>(null);
  const mapsRef = useRef<any>(null);
  const {user} = useUserContext();
  const {register,handleSubmit,formState,setValue} = useForm<CampaignForm>()

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  const navigate = useNavigate()
  const {cities,adBoardTypes} =useAppContext()
  const [creatives,setCreatives] = useState<{
    file:File,
    resolution:{
      width:number,
      height:number
    } 
  }[]>([])  
  const windowSize = useWindowSize();
  const [disabledAdboards, setDisabledAdboards] = useState<AdboardFragment[]>([]);
  const [focussedAdboards, setFocussedAdboards] = useState<AdboardFragment[]>([]);
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: null,
    endDate: null,
  })
  const {config} = useUserContext()
  const [getAdboards,{data}] = useTypedLazyQuery({
    adBoards:[{
      where:{
        isLive:{
          equals:true
        },
        status:{
          notIn:[AdBoardStatus.ARCHIVED]
        },
        workspace:{
          is:{
            type:{
              in:[config?.env == 'production' ? WorkspaceType.PROD : WorkspaceType.TEST] 
            }
          }
        }
      }
    },adboardFragment]
  })

  useEffect(()=>{
    getAdboards()
  },[])
  
  const [selectedAdboards, setSelectedAdboards] = useState<Record<string,AdboardFragment>>({})

  useEffect(()=>{
    if(user){
      setValue('user.name',user.displayName)
      setValue('user.email',user.email)
      setValue('user.phoneNumber',user.phoneNumber)
    }
  },[user])

  useEffect(()=>{

    if(!data?.adBoards.length){
      return;
    }

    const adBoardId = searchParams.get('adboardId')
    const startDate = searchParams.get('startDate')
    const endDate = searchParams.get('endDate')

    if(adBoardId ){
      const adboard = data.adBoards.find(item=>item.id===adBoardId)
      if(adboard){
        setSelectedAdboards({
          [adboard.id]:adboard
        })
        setFocussedAdboards([adboard])
        setSelectedDate({
          startDate:startDate?moment(startDate):null,
          endDate:endDate?moment(endDate):null
        })
      }
      
    }else if(cartAdboards.length && data?.adBoards.length){
      const newSelectedAdboards = {
        ...selectedAdboards
      }
      const adBoardMap = data.adBoards.reduce((acc, item) => {
        if(cartAdboards.find(cart=>cart.id===item.id)){
          acc[item.id] = item
        }
        return acc
      },{})

      for(const item of cartAdboards){
        const adboard = adBoardMap[item.id]
        if(adboard){
          newSelectedAdboards[adboard.id] = adboard
        }
      }
      setSelectedAdboards(newSelectedAdboards)
      setFocussedAdboards([...data.adBoards])
    }
  },[data,cartAdboards,searchParams])

  const [priceSummary, setPriceSummary] = useState<{
    days:number,
    total:number,
    pricePerDay:number,
    gst:number,
    totalWithGST:number
  }>({
    days:0,
    total:0,
    pricePerDay:0,
    gst:0,
    totalWithGST:0
  })
  const [openCampaignSummary,setOpenCampaignSummary] = useState(false);

  useEffect(()=>{
        
    if(!selectedDate.startDate || !selectedDate.endDate){
      return
    }
    
    const days = selectedDate.endDate?.diff(selectedDate.startDate, 'days')+1;

    const totalPricePerDay = Object.values(selectedAdboards).reduce((acc:any, item) => {
      return acc+item.pricePerDay;
    },  0);
    setPriceSummary({
      days:days,
      pricePerDay:totalPricePerDay,
      total:totalPricePerDay * days,
      gst:Number(((totalPricePerDay * days) * GSTFactor).toFixed(0)),
      totalWithGST: Number(((totalPricePerDay * days) * 1.18).toFixed(0))
    })

  },[selectedAdboards,selectedDate])

  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  function resetBounds(){
    const bounds = new mapsRef.current.LatLngBounds();
    
    for(const item of focussedAdboards){ 
      for(const player of item.players||[]){
        if(!player.map) continue
        bounds.extend(new mapsRef.current.LatLng(player.map.lat, player.map.lng),);
      }
    }

    mapRef.current.fitBounds(bounds);
  }

  useEffect(()=>{
    if(!mapsRef.current){
      return
    }
    resetBounds()
    
  },[focussedAdboards])

  function handleCitySelect(e){
    const value = e.target.value as string;
    if(value == "All"){
      setFocussedAdboards([...data?.adBoards||[]])
    }else{
      setFocussedAdboards(data?.adBoards.filter(item=>{
        return item.city.toLowerCase()==value.toLowerCase()
      })||[]) 
    }
  }

  function handleTypeChange(e){
    const value = e.target.value as string;
    if(value == ""){
      setDisabledAdboards([])
      return
    }else{
      setDisabledAdboards(data?.adBoards.filter(item=>{
        return item.type?.name != value
      })||[])
    }
  }

  function handleSelectAllOnMap(){
    if (!mapRef.current) return;

    const bounds = mapRef.current.getBounds();
    const visibleMarkerIds = data?.adBoards.filter(adboard => 
      adboard.players.some(player=>player.map && bounds.contains({ lat: player.map.lat, lng: player.map.lng }))
    ).filter(item=>{
      return disabledAdboards.find(disabled=>disabled.id===item.id)===undefined
    })

    if(!visibleMarkerIds) return;

    const newSelectedAdboards = {
      ...selectedAdboards
    }

    for(const item of visibleMarkerIds){
      newSelectedAdboards[item.id] = item;
    }

    setSelectedAdboards(newSelectedAdboards)
  }

  function clearAllOnMap(){
    setSelectedAdboards({})
    clearCart();
  }

  const renderMarkers = (map,maps) => {
    mapRef.current = map;
    mapsRef.current = maps;
    resetBounds()
  };

  async function handleCreateCampaign(data:CampaignForm){

    if(!user?.id){
      return null;
    }
    if(Object.keys(selectedAdboards).length===0){
      toast.error('Please select at least one adboard');
      return
    }

    if(!selectedDate.endDate || !selectedDate.startDate){
      toast.error('Please select a valid date range');
      return
    }

    if(creatives.length===0){
      toast.error('Please upload at least one creative');
      return
    }

    const assetIds = await Promise.all(creatives?.map(async item => {
      return uploadFile(item.file).then(({ id }) => {
        return id
      })
    }));

    const {createOneCampaignBooking} = await mutation({
      createOneCampaignBooking:[{
        data:{
          assets:{
            connect:assetIds.map(id=>({id}))
          },
          name:data.name,
          businessCategory:{
            connect:{
              id:data.businessCategoryId
            }
          },
          priceSummary:{
            days:priceSummary.days,
            payableRent:priceSummary.total,
            rentPerDay:priceSummary.pricePerDay,
            serviceCharge:Number(priceSummary.gst.toFixed(0)),
            totalRent:Number(priceSummary.totalWithGST.toFixed(0))
          },
          paymentStatus:PaymentStatus.PENDING,
          origin: window.location.origin,
          selectedDates:{
            startDate:selectedDate.startDate,
            endDate:selectedDate.endDate
          },
          user:{
            connect:{
              uid:user?.uid
            }
          },
          bookings: {
            createMany: {
              data: Object.values(selectedAdboards).map(item => {
                const totalRent = item.pricePerDay * priceSummary.days;
                const gst = Number((totalRent * GSTFactor).toFixed(0));
                const payableRent = totalRent + gst;

                return ({
                  priceSummary:{
                    days:priceSummary.days,
                    payableRent:payableRent,
                    rentPerDay:item.pricePerDay,
                    serviceCharge:gst,
                    totalRent:totalRent
                  },
                  isOffline: item.offlineBookingsEnabled,
                  assetsIDs: assetIds,
                  adBoardId: item.id,
                  bookingCode: Math.random().toString(36).substring(7),
                  bookingDetails: {
                    email: user?.email || "",
                    name: user?.displayName || "",
                    phone: user?.phoneNumber
                  },
                  contentApprovalStatus:ContentApprovalStatus.PENDING_REVIEW,
                  deploymentStatus: DeploymentStatus.NOT_STARTED,
                  bookingStatus: BookingStatus.ENQUIRY,
                  selectedDates: {
                    startDate: selectedDate.startDate,
                    endDate: selectedDate.endDate
                  },
                  userId: user?.id
                })
              })
            }
          }
        }
      },{
        id:true
      }]
    }).catch(e=>{
      toast.error('Failed to create campaign, Please try again later');
      throw e;
    })

    fireEvent('campaign_creation_completed',{
      currency: "INR",
      value: priceSummary.totalWithGST,
      items: Object.values(selectedAdboards).map(item=>({
        id:item.id,
        name:item.title,
        quantity:priceSummary.days,
        price:item.pricePerDay
      }))
    });
    toast.success('Campaign created successfully.')
    navigate('/pay-done-campaign/'+createOneCampaignBooking.id)

  }

  function handleOpenCampaignSummary(){
    if(creatives.length==0){
      toast.error('Please upload at least one creative');
      return
    }
    setOpenCampaignSummary(true);
  }

  return (
    <div
      className={`nc-ListingFlightsPage relative ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Campaigns | Adboard Booking</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative py-4 ">
        <div className='flex justify-between items-center' > 
          <Heading desc={user?.uid?"lets start by selecting the adboard":'lets start by logging to your account first'}>Create a new campaign</Heading>
        </div>
        
        {!fbUser?.uid ? <div className="py-48">{authLoading ? <Loader/>:<SocialLogin/>}</div>:
          <div>
            <div className='grid lg:grid-cols-12 grid-cols-1 gap-4 relative'>
              <div className='flex flex-col gap-4 col-span-7 overflow-auto h-full lg:px-2'>
                <Box title="Adboards" classname='flex flex-col gap-2'> 
                  <Table className=''>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Title</TableHead>
                        <TableHead>City</TableHead>
                        <TableHead className="min-w-28 text-center">Price per day</TableHead>
                        <TableHead></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody className=''>
                      {Object.values(selectedAdboards).map((item)=>(  
                        <TableRow key="user1">
                          <TableCell>
                            <div className="flex gap-2">
                              <div className="h-16 w-16 shrink-0" >
                                <NcImage src={item.galleryImgs[0]}/>
                              </div>
                              <Link target="_blank" to={`/hoardings/${titleToUrl(item.title)}/${item.id}`} className="flex items-center gap-2">
                                {item.title}
                              </Link>
                            </div>
                          </TableCell>
                          <TableCell className="whitespace-nowrap">{item.city}</TableCell>
                          <TableCell className="text-center ">{formatPrice(item.pricePerDay)}</TableCell>
                          <TableCell><TrashIcon width={'20'} onClick={()=>{
                            setSelectedAdboards((prev) => {
                              const { [item.id]: {}, ...rest } = prev;
                              return rest;
                            });
                          }}/></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                
                </Box>
                <Box title='Campaign details'>
                  <div className='flex flex-col gap-2'>
                    <Input label="Campaign name" type="text" placeholder='Campaign name' {...register('name',{required:true})} aria-invalid={formState.errors.name ? "true" : "false"}/>
                    {formState.errors.name && <div className='text-red-500 text-sm'>Campaign name is required</div>}
                    <Textarea placeholder='Campaign description' {...register('description')}/>
                    {/* <Select label="Business category" placeholder='Select business category' {...register('businessCategoryId',{required:true})}>
                      <option value="">Select business category</option>
                      {businessCategories.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </Select> */}
                    {/* {formState.errors.businessCategoryId && <div className='text-red-500 text-sm'>Business category is required</div>} */}
                  </div>
                </Box>               
               
                <Box title='Booking user details'>
                  <div className='flex flex-col gap-2'>
                    <Input type="text" label="Name" placeholder='Name' {...register('user.name',{required:true})} aria-invalid={formState.errors.name ? "true" : "false"}/>
                    {formState.errors.user?.name && <div className='text-red-500 text-sm'>Name is required</div>}
                    <Input label="Email" type="email" placeholder='Email' {...register('user.email',{required:true})} aria-invalid={formState.errors.name ? "true" : "false"}/>
                    {formState.errors.user?.email && <div className='text-red-500 text-sm'>Email is required</div>}
                    <Input label="Phone number" type="number" placeholder='+91-9999999999' {...register('user.phoneNumber',{required:true})} aria-invalid={formState.errors.name ? "true" : "false"}/>
                    {formState.errors.user?.phoneNumber && <div className='text-red-500 text-sm'>Phone number is required</div>}
                  </div>
                </Box>               
               
                <Box title="Schedule">
                  <div className="listingSection__wrap__DayPickerRangeController flow-root mx-auto">
                    <div className="flex justify-center">
                      <DayPickerRangeController
                        noBorder
                        minimumNights={0}
                        startDate={selectedDate.startDate}
                        endDate={selectedDate.endDate}
                        onDatesChange={(date) => {
                          setSelectedDate(date);
                          // onChange && onChange(date);
                        }}
                        focusedInput={focusedInputSectionCheckDate}
                        onFocusChange={(focusedInput) =>
                          setFocusedInputSectionCheckDate(focusedInput || "startDate")
                        }
                        numberOfMonths={windowSize.width < 1280 ? 1 : 2}
                        initialVisibleMonth={null}
                        hideKeyboardShortcutsPanel={false}
                        // orientation="vertical"
                        // isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
                        // verticalHeight={420}
                      />
                    </div>
                    {/* <div className="flex flex-col mt-8">
                      <span className="text-sm text-neutral-400">Selected dates</span>
                      <span className="mt-1.5 text-lg font-semibold">
                        {converSelectedDateToString(selectedDate)}
                      </span>
             
                    </div> */}
                   
                    <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                      <div className="flex-1 p-5 flex space-x-4">
                        <svg
                          className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                            stroke="#D1D5DB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <div className="flex flex-col">
                          <span className="text-sm text-neutral-400">Selected dates</span>
                          <span className="mt-1.5 text-lg font-semibold">
                            {moment(selectedDate?.startDate).format('LL')} - {selectedDate?.endDate? moment(selectedDate?.endDate).format('LL'):""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                <Box title="Creatives">
                  <CreativesBox selectedAdboards={Object.values(selectedAdboards)} creatives={creatives} setCreatives={setCreatives} />
                </Box>

                <Box title="Price summary">
                  <div className="flex flex-col space-y-4">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>₹{priceSummary.pricePerDay} x {priceSummary.days} days</span>
                      <span>₹{priceSummary.total}</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>GST (@{GST})</span>
                      <span>₹{priceSummary.gst}</span>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                      <span>Total</span>
                      <span>₹{priceSummary.totalWithGST}</span>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>Payable now (platform fees)</span>
                      <span>₹{FEES_AFTER_DISCOUNT} <s>₹{FEES}</s></span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>Payable on booking approval</span>
                      <span>₹{priceSummary.totalWithGST}</span>
                    </div>
                    
                  </div>
                </Box>

                <div className='flex flex-col justify-end gap-4 mt-2'>
                  <div>
                    <div className='mt-8'>
                      <h2 className='text-lg font-semibold'>Cancellation policy (not applicable)</h2>
                      <s><div className='text-sm'>
                  This reservation is non-refundable. <Link target='_blank' to="/cancellation.html">Learn more</Link>
                      </div></s>
                    </div>
                    <p className='text-xs mt-4'>
            By selecting the button below, I agree to the <a className='text-blue-800' href="https://www.adboardbooking.com/cancellation.html" target="_blank">Adboard Booking Rules, and Refund Policy</a> and that Adboard Booking can charge my payment method if I’m responsible for damage. I agree to pay the total amount shown if the Host accepts my booking request.
                    </p>
                  </div>
                 
                  <div className="flex gap-2 items-end">
                    <ButtonPrimary onClick={handleSubmit(handleOpenCampaignSummary)} className="whitespace-nowrap">Review campaign</ButtonPrimary>
                    <DialogBody isOpen={openCampaignSummary} title="Campaign summary" setOpen={setOpenCampaignSummary}>
                      <CampaignSummary 
                        selectedAdboards={Object.values(selectedAdboards)} 
                        selectedDate={selectedDate} 
                        priceSummary={priceSummary} 
                        creatives={creatives}
                        onClick={handleSubmit(handleCreateCampaign)}
                      />
                    </DialogBody> 
                  </div>
                  <span className="text-xs">
                    Clicking above button will create a campaign and you will be redirected to the campaign page. Upon approval of the campaign, you will be charged the total amount shown above.
                  </span>
                </div>
              
                <div className='mb-2'></div>
              </div>
              {!showFullMapFixed && (
                <div
                  className="flex  xl:hidden items-center justify-center fixed bottom-16 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
                  onClick={() => setShowFullMapFixed(true)}
                >
                  <i className="text-lg las la-map"></i>
                  <span>Select on map</span>
                </div>
              )}
              
              <div className={`xl:flex-grow xl:static xl:block col-span-5 ${
                showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
              }`}>
                {showFullMapFixed && (
                  <ButtonClose
                    onClick={() => setShowFullMapFixed(false)}
                    className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                  />
                )}
                <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">

                  <Box>
                    <div className="lg:flex gap-2 mb-4 items-end hidden">
                      <Select placeholder='Select city' label='Select city' onChange={handleCitySelect} >
                        {/* <option value="">Select city</option> */}
                        {/* <option value="">Everywhere</option> */}
                        {cities.map((item) => (
                          <option value={item.city}>{item.city}</option>
                        ))}
                      </Select>
                      <Select label='Select adboard type' onChange={handleTypeChange}>
                        <option value="">Select all</option>
                        {adBoardTypes.map((item) => (
                          <option value={item.name}>{item.name}</option>
                        ))}
                      </Select>
                    </div>
                    <div
                      className={`xl:flex-grow xl:static xl:block ${
                        showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
                      }`}
                    >
                      {showFullMapFixed && (
                        <ButtonClose
                          onClick={() => setShowFullMapFixed(false)}
                          className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                        />
                      )}

                      <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full lg:h-[600px] rounded-md overflow-hidden">
                        <div className="flex gap-2 absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 z-10 rounded-2xl min-w-max">
                          <ButtonSecondary className="outline outline-blue-600"
                            onClick={handleSelectAllOnMap}
                          >Select all</ButtonSecondary>
                          <ButtonSecondary className="outline outline-blue-600"
                            onClick={clearAllOnMap}
                          >Reset</ButtonSecondary>
                        </div>

                        {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}
                        {data?.adBoards.length ? 
                          <GoogleMapReact
                            resetBoundsOnResize
                            defaultZoom={14}
                            defaultCenter={{ lat: 28.6139, lng: 77.2090 }}
                            bootstrapURLKeys={{
                              key: GMAP_API_KEY,
                            }}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map ,maps}) => renderMarkers(map,maps)}
                          >
                            {data?.adBoards.map((adboard) => {
                              return adboard.players.map((item,i)=>{
                                return item.map && <MapMarker
                                  onHover={(adb)=>{
                                    setCurrentHoveredAdboard(adb)
                                  }}
                                  isHovered={currentHoveredAdboard?.id===adboard.id}
                                  // disabled = {!!disabledAdboards.find(disabled=>disabled.id===adboard.id)}
                                  onClick={() => {
                                    fireEvent('adboard_added_to_campaign',{
                                      currency: "INR",
                                      value: priceSummary.totalWithGST,
                                      items: Object.values(selectedAdboards).map(item=>({
                                        id:item.id,
                                        name:item.title,
                                        quantity:priceSummary.days,
                                        price:item.pricePerDay
                                      }))
                                    });
                                    setSelectedAdboards((prev) => {
                                      if (prev[adboard.id]) {
                                        const { [adboard.id]: {}, ...rest } = prev;
                                        return rest;
                                      } else {
                                        return { ...prev, [adboard.id]: adboard };
                                      }
                                    })
                                  }}
                                  isSelected={!!selectedAdboards[adboard.id]}
                                  key={i}
                                  lat={item.map.lat}
                                  lng={item.map.lng}
                                  listing={adboard}
                                />
                              })
                            })}
                          </GoogleMapReact>:<></>}
                  
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
      
  )
}
