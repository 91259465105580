import React from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "@/components/ui/card";
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { Link } from 'react-router-dom';

const FrustrationToFreedom = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6">From Frustration to Freedom: A Billboard Owner's Journey</h1>
      
      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Meet Rajesh</CardTitle>
        </CardHeader>
        <CardContent>
          <p>A seasoned billboard owner in Mumbai, Rajesh prided himself on his network of prime locations across the city. But lately, the spark had gone out of his eyes. Let's follow Rajesh's journey from overwhelmed business owner to thriving entrepreneur.</p>
        </CardContent>
      </Card>

      <h2 className="text-2xl font-semibold mb-4">The Daily Grind</h2>
      <p className="mb-4">Rajesh's day used to start before the sun rose, with a familiar knot of anxiety in his stomach. He'd rush to his office, knowing an avalanche of work awaited him.</p>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>A Typical Day in Rajesh's Life:</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="list-disc pl-6">
            <li><strong>6:00 AM:</strong> Arrives at the office, greeted by a blinking answering machine and an inbox bursting with unread messages.</li>
            <li><strong>8:30 AM:</strong> Three cups of chai later, barely makes a dent in his emails. A frantic call comes in from an advertiser needing space for a last-minute campaign.</li>
            <li><strong>11:00 AM:</strong> Juggles phone calls, trying to match advertisers with available billboards. His whiteboard is a mess of scribbles and crossed-out dates.</li>
            <li><strong>2:00 PM:</strong> A lucrative booking falls through because he couldn't confirm availability fast enough.</li>
            <li><strong>5:00 PM:</strong> Still at his desk, drafting contracts and chasing payments. His son's cricket match started an hour ago – another family event missed.</li>
          </ul>
        </CardContent>
      </Card>

      <blockquote className="border-l-4 border-gray-300 pl-4 italic mb-8">
        "Another day, another battle," Rajesh sighs, settling into his chair.
      </blockquote>

      <Separator className="my-8" />

      <h2 className="text-2xl font-semibold mb-4">The Breaking Point</h2>
      <p className="mb-4">One evening, after another exhausting day, Rajesh bumps into his old friend Priya at a local café. Priya, also in the billboard business, looks relaxed and happy.</p>
      <p className="mb-4">"How do you do it, Priya?" Rajesh asks, bewildered. "I'm drowning in work, and you look like you've just come back from vacation!"</p>
      <p className="mb-4">Priya smiles knowingly. "Let me tell you about <Badge variant="secondary">AdBoard Booking's Quick Book</Badge> feature..."</p>

      <Separator className="my-8" />

      <h2 className="text-2xl font-semibold mb-4">A New Dawn</h2>
      <p className="mb-4">Intrigued by Priya's story, Rajesh decides to give AdBoard Booking a try. The onboarding process is simple, and within a day, all his billboards are uploaded to the platform.</p>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Day 1 with Quick Book:</CardTitle>
        </CardHeader>
        <CardContent>
          <ol className="list-decimal pl-6">
            <li><strong>6:00 AM:</strong> Rajesh wakes up, reaches for his phone out of habit, and is shocked to see notifications of three new bookings that came in overnight.</li>
            <li><strong>9:00 AM:</strong> At the office, Rajesh logs into his AdBoard Booking dashboard. He sees real-time availability for all his billboards at a glance. A new inquiry comes in, and with just a few clicks, he confirms the booking instantly.</li>
            <li><strong>2:00 PM:</strong> Rajesh realizes he hasn't had to make a single phone call about bookings all day. The system has handled everything automatically.</li>
            <li><strong>5:00 PM:</strong> For the first time in years, Rajesh leaves the office on time. He makes it to his son's cricket match, watching proudly as the boy scores the winning run.</li>
          </ol>
        </CardContent>
      </Card>

      <blockquote className="border-l-4 border-gray-300 pl-4 italic mb-8">
        "Is this for real?" Rajesh mutters, rubbing his eyes in disbelief at the overnight bookings.
      </blockquote>

      <h2 className="text-2xl font-semibold mb-4">The Transformation</h2>
      <p className="mb-4">Over the next few weeks, Rajesh's business undergoes a dramatic transformation:</p>

      <Table className="mb-8">
        <TableHeader>
          <TableRow>
            <TableHead>Aspect</TableHead>
            <TableHead>Improvement</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>Billboard Occupancy</TableCell>
            <TableCell>⬆️ 35% increase</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Administrative Work</TableCell>
            <TableCell>⬇️ 50% reduction</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last-Minute Bookings</TableCell>
            <TableCell>From stress source to regular, easy part of business</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <p className="mb-4">But the most significant change is in Rajesh himself. The spring is back in his step, and the spark has returned to his eyes.</p>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Rajesh's New Reality:</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="list-disc pl-6">
            <li>More time for business expansion</li>
            <li>Increased family time</li>
            <li>Renewed enjoyment in his work</li>
            <li>Stress-free booking process</li>
            <li>Automated administrative tasks</li>
          </ul>
        </CardContent>
      </Card>

      <blockquote className="border-l-4 border-gray-300 pl-4 italic mb-8">
        "I'm not just making more money," Rajesh tells Priya over coffee. "I've reclaimed my life. I'm expanding my network, spending time with my family, and actually enjoying my work again. I didn't realize how much I was missing out on before."
      </blockquote>

      <h2 className="text-2xl font-semibold mb-4">Your Turn to Transform</h2>
      <p className="mb-4">Rajesh's story isn't unique. Countless billboard owners across India are discovering the freedom and success that comes with embracing AdBoard Booking's Quick Book feature.</p>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Are you ready to write your own success story?</CardTitle>
        </CardHeader>
        <CardContent>
          <ol className="list-decimal pl-6">
            <li>Visit AdBoardBooking.com today</li>
            <li>Sign up for a free demo</li>
            <li>Experience the power of Quick Book</li>
            <li>Transform your billboard business</li>
          </ol>
        </CardContent>
      </Card>

      <p className="mb-8">Don't let another day of opportunities slip away. Your journey from frustration to freedom starts now.</p>

      <Link to="/publisher">
        <Button size="lg">Get Started with AdBoard Booking</Button>
      </Link>
    </div>
  );
};

export default FrustrationToFreedom;