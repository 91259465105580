import { ApolloProviderWrapper } from '@/context/ApolloClientContext';
import { AppProvider } from '@/context/AppContext';
import CartProvider from '@/context/CartContext'; 
import { FirebaseProvider } from '@/context/FirebaseContext';
import { UserProvider } from '@/context/UserContext'; 
import { ToastContainer } from '@/lib/toast';
import MyRouter from "@/routers/index";
 
function App() { 
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <FirebaseProvider>
        <UserProvider>
          <ApolloProviderWrapper>
            <AppProvider>
              <CartProvider>
                <MyRouter />
                <ToastContainer
                  autoClose={2000}
                  position="top-right"
                  hideProgressBar={false}
                  newestOnTop={false}
                  rtl={false}
                  draggable
                  closeOnClick={true}
                />
              </CartProvider>
            </AppProvider>
          </ApolloProviderWrapper>
        </UserProvider> 
      </FirebaseProvider>
    </div>
  );
}

export default App;
