import React from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "@/components/ui/card";
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const ChaosToClarity = () => {
  return (

    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>
            From Chaos to Clarity: An Agency's Billboard Booking Revolution
        </title>
      </Helmet>
      <h1 className="text-4xl font-bold mb-6">From Chaos to Clarity: An Agency's Billboard Booking Revolution</h1>
      
      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Meet Priya and Pixel Perfect Digital</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Priya, the dynamic founder of Pixel Perfect Digital, a bustling marketing agency in Bangalore, had always prided herself on delivering cutting-edge campaigns for her clients. But when it came to booking outdoor advertising, she felt stuck in the Stone Age. Let's follow Priya's journey from frazzled agency owner to outdoor advertising virtuoso.</p>
        </CardContent>
      </Card>

      <h2 className="text-2xl font-semibold mb-4">The Billboard Booking Nightmare</h2>
      <p className="mb-4">For Priya, securing billboard space for her clients' campaigns was a constant source of stress and inefficiency. Here's what her typical billboard booking process looked like:</p>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>A Week in Priya's Billboard Booking Saga:</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="list-disc pl-6">
            <li><strong>Monday:</strong> Spend hours researching available billboards in target locations.</li>
            <li><strong>Tuesday:</strong> Send out multiple inquiries to billboard owners, hoping for quick responses.</li>
            <li><strong>Wednesday:</strong> Anxiously wait for availability confirmations, watching campaign deadlines loom closer.</li>
            <li><strong>Thursday:</strong> Engage in back-and-forth negotiations on pricing and terms with multiple owners.</li>
            <li><strong>Friday:</strong> Rush to finalize paperwork and contracts, praying everything falls into place in time.</li>
          </ul>
        </CardContent>
      </Card>

      <blockquote className="border-l-4 border-gray-300 pl-4 italic mb-8">
        "I felt like I was playing a game of chance every time we included outdoor advertising in a campaign," Priya sighs, recalling the stress.
      </blockquote>

      <Separator className="my-8" />

      <h2 className="text-2xl font-semibold mb-4">The Breaking Point</h2>
      <p className="mb-4">The real crisis hit when Pixel Perfect landed their biggest client yet - a major tech startup planning a city-wide product launch. The client wanted billboards in prime locations across Bangalore, Mumbai, and Delhi, with just two weeks to go live.</p>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>The High-Stakes Campaign:</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="list-disc pl-6">
            <li>15 premium billboards needed</li>
            <li>3 major cities</li>
            <li>2-week deadline</li>
            <li>₹50 lakh campaign budget at stake</li>
          </ul>
        </CardContent>
      </Card>

      <p className="mb-4">Priya and her team worked around the clock, calling billboard owners, sending emails, and negotiating terms. But as the deadline approached, they had only secured 3 out of the 15 needed locations.</p>

      <blockquote className="border-l-4 border-gray-300 pl-4 italic mb-8">
        "I was on the verge of telling our biggest client that we couldn't deliver. It felt like watching our agency's future slip away," Priya recalls, the stress evident in her voice.
      </blockquote>

      <Separator className="my-8" />

      <h2 className="text-2xl font-semibold mb-4">The Game-Changing Discovery</h2>
      <p className="mb-4">Just when all seemed lost, Priya's account manager, Rahul, stumbled upon a LinkedIn post about <Badge variant="secondary">AdBoard Booking's Quick Book</Badge> feature. Desperate for a solution, Priya decided to give it a try.</p>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Priya's Quick Book Experience:</CardTitle>
        </CardHeader>
        <CardContent>
          <ol className="list-decimal pl-6">
            <li><strong>9:00 AM:</strong> Signs up for AdBoard Booking and explores the platform.</li>
            <li><strong>9:30 AM:</strong> Searches for available billboards in Bangalore, Mumbai, and Delhi.</li>
            <li><strong>10:15 AM:</strong> Identifies 20 suitable options across the three cities.</li>
            <li><strong>11:00 AM:</strong> Books 15 premium locations with just a few clicks.</li>
            <li><strong>11:30 AM:</strong> Receives instant confirmations and invoices for all bookings.</li>
          </ol>
        </CardContent>
      </Card>

      <blockquote className="border-l-4 border-gray-300 pl-4 italic mb-8">
        "I couldn't believe it. In less than three hours, we had achieved what usually took weeks. It felt like magic!" Priya exclaims.
      </blockquote>

      <h2 className="text-2xl font-semibold mb-4">The Transformation</h2>
      <p className="mb-4">The successful booking of the tech startup's campaign was just the beginning. Over the next few months, Pixel Perfect Digital's outdoor advertising process underwent a complete transformation:</p>

      <Table className="mb-8">
        <TableHeader>
          <TableRow>
            <TableHead>Aspect</TableHead>
            <TableHead>Before Quick Book</TableHead>
            <TableHead>After Quick Book</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>Booking Time</TableCell>
            <TableCell>1-2 weeks</TableCell>
            <TableCell>1-2 hours</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Campaign Planning</TableCell>
            <TableCell>Uncertain and stressful</TableCell>
            <TableCell>Confident and efficient</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Client Satisfaction</TableCell>
            <TableCell>Often compromised</TableCell>
            <TableCell>Consistently high</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Outdoor Ad Revenue</TableCell>
            <TableCell>15% of total billings</TableCell>
            <TableCell>35% of total billings</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>The New Reality for Pixel Perfect Digital:</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="list-disc pl-6">
            <li>Ability to offer last-minute outdoor campaigns</li>
            <li>Expanded service offerings to include comprehensive outdoor advertising solutions</li>
            <li>Improved work-life balance for the team</li>
            <li>Increased profitability and client retention</li>
            <li>Reputation as the go-to agency for integrated digital and outdoor campaigns</li>
          </ul>
        </CardContent>
      </Card>

      <blockquote className="border-l-4 border-gray-300 pl-4 italic mb-8">
        "AdBoard Booking didn't just save our biggest campaign; it revolutionized our entire approach to outdoor advertising. We're now able to deliver results we never thought possible," Priya reflects, beaming with pride.
      </blockquote>

      <h2 className="text-2xl font-semibold mb-4">Your Agency's Turn to Transform</h2>
      <p className="mb-4">Pixel Perfect Digital's story isn't unique. Agencies across India are discovering the power of AdBoard Booking's Quick Book feature to transform their outdoor advertising capabilities.</p>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Ready to revolutionize your agency's outdoor advertising process?</CardTitle>
        </CardHeader>
        <CardContent>
          <ol className="list-decimal pl-6">
            <li>Visit AdBoardBooking.com today</li>
            <li>Sign up for a free agency demo</li>
            <li>Experience the Quick Book difference</li>
            <li>Transform your outdoor advertising offerings</li>
          </ol>
        </CardContent>
      </Card>

      <p className="mb-8">Don't let outdated booking processes hold your agency back. Join Priya and countless other agency leaders in embracing the future of outdoor advertising.</p>
      <Link to="/">
        <Button size="lg">Elevate Your Agency with AdBoard Booking</Button>
      </Link>
    </div>
  );
};

export default ChaosToClarity;