import React from 'react'

export default function FilePreview({
  file
}:{
    file:File
}) {

  return <div className='max-w-40 '>
    <div className='rounded-lg overflow-hidden'>
      {
        file.type.includes('image') && <img className='' src={URL.createObjectURL(file)} alt=""/>
      }
      {
        file.type.includes('video') && <video src={URL.createObjectURL(file)} controls></video>
      }
    </div>
    <div className='text-center'>
      {file.name.length>20?file.name.slice(0,20)+'...':file.name}
    </div>
  </div>    
}
