import { Transition ,Dialog as HLDialog} from '@headlessui/react'
import React, { Fragment, useEffect } from 'react'
import ButtonClose from '@/shared/ButtonClose/ButtonClose';

const DialogContext = React.createContext<{
  isOpen:boolean,
  title:string,
  setOpen?:(arg:boolean)=>void
    }>({
      isOpen: false,
      title:"",
    });

type DialogProps={title:string,
  children:React.ReactNode
  open?:boolean,onClose?:()=>void}

function Dialog({title,children,open,onClose}:DialogProps) {

  const [isOpen,setOpen] = React.useState<boolean>(open||false);

  useEffect(()=>{
    setOpen(open||false);
    if(!open){
      onClose?.();
    }
  },[open])

  return (
    <DialogContext.Provider value={{isOpen: isOpen,title,setOpen}}>
      {children}
    </DialogContext.Provider> 
  )
}

export function DialogBody({
  children,
  isOpen,
  setOpen,
  title
}:{
  title:  string,
  setOpen?:(arg:boolean)=>void,
  children:React.ReactElement|((props:{close:()=>void})=>React.ReactNode),
  isOpen:boolean
}){
  return  <Transition appear show={isOpen} as={Fragment}>
    <HLDialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={()=>setOpen?.(false)}
    >
      <div className="min-h-screen px-4 text-center ">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <HLDialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        >
        &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="inline-block py-4 h-screen pb-4 w-full max-w-4xl ">
            <div className="inline-flex flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full  pb-16">
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <h3
                  className="text-lg font-medium leading-6"
                  id="headlessui-dialog-title-70"
                >
                  {title}
                </h3>
                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={()=>setOpen?.(false)} />
                </span>
              </div>
              <div className="px-2 md:px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200 h-full">
                <div
                  className="items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8 h-full"
                > 
                  {typeof children == 'function' ? children({close}) : React.cloneElement(children,{close})}
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </HLDialog>
  </Transition>
}

Dialog.Body = function DialogBodyFn({children}:{children:React.ReactElement|((props:{close:()=>void})=>React.ReactNode),}){

  const {isOpen,setOpen,title} = React.useContext(DialogContext);

  function close(){
    setOpen?.(false);
  }
  return <DialogBody isOpen={isOpen} setOpen={setOpen} title={title}>
    {children}
  </DialogBody>
  
}

Dialog.Trigger = function DialogTrigger({children}:{children:React.ReactNode}){
  const {setOpen} = React.useContext(DialogContext);

  function handleClick(){
    setOpen?.(true)
  }
  return <span onClick={handleClick}>
    {children}
  </span>
}

export const useDialog = function(){
  const {isOpen,setOpen} = React.useContext(DialogContext);
  return {
    isOpen,
    setOpen
  }
}

export {
  Dialog
}

