import MediaLibraryPicker from '@/containers/MediaAssetsPage/MediaLibraryPicker';
import { FileData } from '@/containers/MediaAssetsPage/useMediaAssets';
import { useState } from 'react';

export default function FileUpload(props:{label?:string,onFileSelection?:(file:(File|FileData)[])=>void}) {

  const [files,setFiles] = useState<(File|FileData)[]>();

  function handleFileChange(e:any){
    setFiles([...e.target.files]);
    props.onFileSelection?.([...e.target.files])
  }

  function handleFileSelection(file:FileData){
    setFiles([file])
    props.onFileSelection?.([file]);
  }

  return (

    <>

      <div className="mt-4">
        <span className="text-lg font-semibold">{props.label||"Upload your media file"}</span>
        <div>
          {files && files.map(item=>{
            if(item instanceof File){

              if(item.type.startsWith("video")){
                return <video className="w-full my-4 rounded-lg " controls>
                  <source src={URL.createObjectURL(item)} type={item.type}/>
                </video>
              }else{
                return <img className="h-[200px] my-4 max-w-lg rounded-lg" src={URL.createObjectURL(item)} alt="image description"/>
              }
            }else{
              if(item.type.startsWith("video")){
                return <video className="h-[200px] my-4 rounded-lg " controls>
                  <source src={item.url} type={item.type}/>
                </video>
              }else{
                return <img className="h-[200px] my-4 max-w-lg rounded-lg" src={item.url} alt="image description"/>
              }
            }
          })}
        </div>
      </div>

      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-neutral-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
            >
              <span>Upload a file</span>
              <input
                required
                accept="image/*,video/*"
                // onSelect={handleFileChange}
                onChange={handleFileChange}
                multiple={false}
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-neutral-500 dark:text-neutral-400">
              PNG, JPG, GIF, MP4, AVI up to 50MB
          </p>
        </div>
      </div>
      <div>
        <MediaLibraryPicker onCardSelect={file=>handleFileSelection(file)}/>

      </div>
    </>
  )
}
