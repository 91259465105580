import { DATE_FORMAT } from "@/contains/contants";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { FocusedInputShape } from "react-dates";
import { useLocation } from "react-router-dom";
import { PathName } from "@/routers/types";
import ButtonSubmit from "./ButtonSubmit";
import LocationInput from "./LocationInput";
import StayDatesRangeInput from "./StayDatesRangeInput";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = "All";
const defaultDateRange = {
  startDate: moment(),
  endDate: moment(),
};

const StaySearchForm: FC<StaySearchFormProps> = ({
}) => {
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });
  
  const [locationInputValue, setLocationInputValue] = useState("");

  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );

  const location  = useLocation();
  
  useEffect(() => {
    
    const params = new URLSearchParams(location.search);
    const startDate = params.get('startDate')
    const endDate = params.get('endDate');
      
    const locationName = params.get('location')
    setDateRangeValue(startDate?{
      startDate:moment(startDate, DATE_FORMAT)||null,
      endDate:moment(endDate, DATE_FORMAT)||null,
    }:defaultDateRange)
    setLocationInputValue(locationName||defaultLocationValue);
    
  }, [location.search]);

  let href:PathName = `/adboards?location=${locationInputValue}`
  if(dateRangeValue.startDate){
    href+=`&startDate=${dateRangeValue.startDate?.format(DATE_FORMAT)}`
  }
  if(dateRangeValue.endDate){
    href+=`&endDate=${dateRangeValue.endDate?.format(DATE_FORMAT)}`
  }

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <LocationInput
          defaultValue={locationInputValue}
          onChange={(e) => {setLocationInputValue(e)}}
          onInputDone={() => setDateFocused("startDate")}
          className="flex-[1.5]"
        />
        <StayDatesRangeInput
          defaultValue={dateRangeValue}
          defaultFocus={dateFocused}
          onChange={(data) => setDateRangeValue(data)}
          className="flex-[2]"
        />
        {/* <GuestsInput
          defaultValue={guestValue}
          onChange={(data) => setGuestValue(data)}
          className="flex-[1.2]"
        /> */}
        
        <div
          className={`ml-8 justify-end flex items-center focus:outline-none cursor-pointer`}
        > 
          <div className="pr-2 xl:pr-4" onClick={(e)=>{
            (e.target as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
          }}>
            <ButtonSubmit href={href}/>
          </div>
        </div>
      </form> 
    );
  };

  return renderForm();
};

export default StaySearchForm;
