import { useEffect } from "react";
import { useTypedLazyQuery } from "./useZeus";
import { AdBoardStatus, GraphQLTypes, InputType, Selector, WorkspaceType } from "@/generated/zeus";
import useUserContext from "./useUserContext";

export const adboardFragment = Selector('AdBoard')({
  pricePerDay:true, 
  status:true,
  id:true,
  href:true,
  title:true,
  featuredImage:true,
  type:[{
  },{
    name:true
  }],
  players:[{},{
    resolution:{
      width:true,
      height:true,
    },
    map:{
      lat:true,
      lng:true,
    }
  }],
  offlineBookingsEnabled:true,
  city:true,
  originalPricePerDay:true,
  address:true,
  galleryImgs:true,
  resolution:{
    width:true,
    height:true,
  },
  screenSize:{
    width:true,
    height:true,
    unit:true,
  }
});

export type AdboardFragment = InputType<GraphQLTypes['AdBoard'], typeof adboardFragment>;

export function useAdboards({
  fetch
}:{
  fetch?:boolean
}={
  fetch:false
}){
  const {config}=useUserContext()
  const [getAdboards,values] = useTypedLazyQuery({
    adBoards:[{
      where:{
        isLive:{
          equals:true
        },
        status:{
          notIn:[AdBoardStatus.ARCHIVED]
        },
        workspace:{
          is:{
            type:{
              in:[config?.env == 'production' ? WorkspaceType.PROD : WorkspaceType.TEST] 
            }
          }
        }
      }
    },adboardFragment]
  })

  useEffect(()=>{
    if(fetch){
      getAdboards()
    }
  },[])

  return {getAdboards,...values};
} 