import { GMAP_API_KEY } from '@/contains/contants';
import { AdBoardStatus, WorkspaceType } from '@/generated/zeus';
import useUserContext from '@/hooks/useUserContext';
import { useTypedQuery } from '@/hooks/useZeus';
import ButtonClose from '@/shared/ButtonClose/ButtonClose';
import Heading from '@/shared/Heading/Heading';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import GoogleMapReact from "google-map-react";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function PageHomeMap() {
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const markerClustererRef = React.useRef<MarkerClusterer | null>(null);
  const markersRef = React.useRef<any>([]);
  const { config } = useUserContext()
  const navigate=  useNavigate()

  const { data } = useTypedQuery({
    adBoards: [{
      where: {
        isLive: {
          equals: true
        },
        status: {
          notIn: [AdBoardStatus.ARCHIVED]
        },
        workspace: {
          is: {
            type: {
              in: [config?.env == 'production' ? WorkspaceType.PROD : WorkspaceType.TEST]
            }
          }
        }
      }
    }, {
      id: true,
      title: true,
      players:[{},{
        map: {
          lat: true,
          lng: true
        }
      }]
    }]
  })

  const listings = data?.adBoards || []

  const createClusteredMarkers = (map: google.maps.Map, maps: any) => {
    // Clear existing markers
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    const bounds = new maps.LatLngBounds();
  
    // Create new markers
    for(const item of listings){
      item.players.forEach(player => {
        if (player.map) {
          bounds.extend({ lat: player.map.lat, lng: player.map.lng })
          const marker = new maps.Marker({
            position: player.map,
            map: map,
            title: item.title,
            // You can customize the marker icon here if needed
          });
          marker.addListener("click", () => {
            // map.setZoom(8);
            navigate('/adboards/' + item.id+window.location.search+"#page-home-map");
            // map.setCenter(marker.position as google.maps.LatLng);
          });
          marker.setMap(map);
          markersRef.current.push(marker);
        }
      });
    }

    map.fitBounds(bounds);

    // Clear existing clusterer
    if (markerClustererRef.current) {
      markerClustererRef.current.clearMarkers();
    }

    // Create new clusterer with custom renderer
    markerClustererRef.current = new MarkerClusterer({
      map,
      markers: markersRef.current
    });
  };

  return (
    <div
      className={`xl:flex-grow xl:static xl:block ${showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
      }`}
    >

      <Heading isCenter desc="Discover Our Extensive Network of Digital Display Locations">
      Your Ad, Our Screens, Infinite Reach
      </Heading>
      
      {showFullMapFixed && (
        <ButtonClose
          onClick={() => setShowFullMapFixed(false)}
          className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
        />
      )}

      <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[600px] rounded-md overflow-hidden">
        {listings.length > 0 &&
          <GoogleMapReact
            resetBoundsOnResize
            zoom={12}
            defaultCenter={listings[0]?.players[0]?.map}
            bootstrapURLKeys={{
              key: GMAP_API_KEY,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              createClusteredMarkers(map, maps);
            }}
          >

          </GoogleMapReact>
        }
      </div>
    </div>
  )
}
