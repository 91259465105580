import { FileStatus } from "@/generated/zeus";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import ncNanoId from "@/utils/ncNanoId";
import useUserContext from "./useUserContext";
import { mutation } from "./useZeus";
export interface FileStat {
  mtime: string
  ctime: string
  contentType: string
  size: number
  isDirectory: boolean
  meta: Meta
}

export interface Meta {
  width: number
  height: number
  duration: number
}

function fetchWithProgress(url:string,options:RequestInit, progressCallback?:(percent:number)=>void){

  const {body} = options;
  if(!body){
    return Promise.reject(1);
  }

  return new Promise((resolve,reject)=>{
    const xhr = new XMLHttpRequest();
    
    xhr.upload.addEventListener("progress", ProgressHandler, false);
    xhr.addEventListener("load", SuccessHandler, false);
    xhr.addEventListener("error", ErrorHandler, false);
    // xhr.addEventListener("abort", AbortHandler, false);
    xhr.open("POST", url);
    for(const key of Object.keys(options.headers||{})){
      //@ts-ignore
      if(options.headers?.[key]) xhr.setRequestHeader(key,options.headers[key])
    }
    //@ts-ignore
    xhr.send(body);

    function ProgressHandler(e:any){
      const percent = (e.loaded / e.total) * 100;
      progressCallback?.(percent)
    }

    function ErrorHandler(){
      reject(1)
    }

    function SuccessHandler(){
      resolve(0);
    }
  })
}

export function useFileUploader(){
  
  const [loading,setLoading] = React.useState(false);
  const {user} = useUserContext();

  async function uploadFileToSirv({
    file,token
  }:{
    file:File,
    token:string
  },progressCallback?:(percent:number)=>void){
    const filePath  = '/uploads/'+moment().format("MMM-YYYY")+'/' +ncNanoId()+"_"+ encodeURIComponent(file.name);
    return await fetchWithProgress('https://api.sirv.com/v2/files/upload?filename='+(filePath),{
      method: "POST",
      body: file,
      credentials:'omit',
      headers: {
        // "Content-length": file.size + "",
        'authorization': 'Bearer ' + token,
        'content-type': file.type
      }
    },progressCallback).then(()=>{
      const url =  "https://aplivili.sirv.com"+filePath
      return {
        url,filePath
      }
    }).finally(()=>{
      setLoading(false)
    })
  }

  async function getFileStats(filePath:string,{token}:{token:string}):Promise<FileStat>{
    return fetch("https://api.sirv.com/v2/files/stat?filename="+filePath, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then(res=>res.json())
  }
  
  async function uploadFile(file:File,progressCallback?:(percent:number)=>void){
    setLoading(true);
    
    if(!user?.uid){
      toast.error("User not logged in");
      throw new Error("User not logged in");
    }

    const {token} = await fetch(import.meta.env.VITE_PUBLIC_API_URL+"/api/sirv/token").then(res=>res.json()).catch(e=>{
      console.error(e);
      toast.error("Internal server error, please try again later");
      throw new Error("Sirv token not found");
    });

    if(!token){
      throw new Error("Sirv token not found");
    }

    const {url,filePath} = await uploadFileToSirv({
      file,
      token,
    },progressCallback);

    const fileStats = await getFileStats(filePath,{
      token
    })  

    const {createOneFile} = await mutation({
      createOneFile:[{
        data:{
          ctime:fileStats.ctime, 
          filename:file.name, 
          isDirectory:fileStats.isDirectory, 
          meta:fileStats.meta, 
          mtime:fileStats.mtime,
          contentType:fileStats.contentType,
          name:file.name,
          url,
          status:FileStatus.REQUEST_APPROVAL,
          size:fileStats.size,
          type:fileStats.contentType,
          createdBy:{
            connect:{
              uid:user.uid
            }
          }
        }
      },{
        id:true
      }]
    }).catch(e=>{
      console.error(e);
      toast.error("Failed to upload file, please try again later");
      throw new Error("File not uploaded");
    })

    return {
      url,
      id: createOneFile.id
    }
  }
  
  return {
    loading,
    uploadFile
  }
}