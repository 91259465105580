import BgGlassmorphism from "@/components/BgGlassmorphism/BgGlassmorphism";
import Box from "@/components/Box";
import Heading from "@/components/Heading/Heading";
import StayDatesRangeInput from "@/components/HeroSearchForm2/StayDatesRangeInput";
import LoadingButton from "@/components/LoadingButton";
import { DATE_FORMAT, GST, GSTFactor } from "@/contains/contants";
import { mutation, useTypedQuery } from "@/hooks/useZeus";
import Input from "@/shared/Input/Input";
import moment from "moment";
import { FC, useEffect, useState, } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MediaLibraryPicker from "../MediaAssetsPage/MediaLibraryPicker";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import NcImage from "@/shared/NcImage/NcImage";
import LocationPointerIcon from "@/components/icon/LocationPointerIcon";
import Loader from "@/shared/FullScreenLoader/Loading";

export interface CheckOutPageProps {
  className?: string;
  orderId?: string
}

const ManageCampaignPage: FC<CheckOutPageProps> = ({ }) => {
  
  const {id} = useParams<string>()

  const {data:campaignData,refetch,loading} = useTypedQuery({
    campaign:[{
      where:{
        id
      }
    },{
      assets:[{},{
        meta:{
          height:true,
          width:true
        },

        id:true,
        url:true,
        type:true
      }],
      
      selectedDates:{
        startDate:true,
        endDate:true
      },
      payment:{
        totalDetails:{
          total:true,
          discount:true,
          tax:true,
          subtotal:true
        }
      },
      id:true,
      name:true,
      businessCategory:{
        name:true
      },
      bookings:[{
        
      },{
        id:true,
        priceSummary:{
          totalRent:true,
          payableRent:true,
          days:true,
          rentPerDay:true,
          serviceCharge:true
        },
        adBoard:{
          city:true,
          pricePerDay:true,
          address:true,
          title:true,
          galleryImgs:true,
        }
      }]
    }]
  })

  const {startDate,endDate} = campaignData?.campaign?.selectedDates||{}
  const [,setCreatives] = useState<{
    file:{
      name:string
    },
    resolution:string
  }[]>([])

  useEffect(()=>{
    setCreatives(campaignData?.campaign?.assets?.map(item=>({
      file:{
        name:item.url
      },
      resolution:item.meta?.width + ' x ' + item.meta?.height 
    })) || []
    )
  },[campaignData])

  const selectedDate ={
    startDate: moment(startDate, DATE_FORMAT) ,
    endDate: moment(endDate, DATE_FORMAT)
  }

  const rentPerDay = campaignData?.campaign?.bookings.map(item=>item.priceSummary?.rentPerDay||0).reduce((a,b)=>a+b,0)||0;

  const days = 1 + (selectedDate.endDate?.diff(selectedDate.startDate, 'days') || 0);
  const totalRent = rentPerDay * days;
  const serviceCharge = totalRent * GSTFactor;
  const payableRent = totalRent + serviceCharge;

  const order = {
    priceSummary: {
      rentPerDay,
      days,
      totalRent,
      serviceCharge,
      payableRent
    }
  }

  async function handleSave(file:{id:string}){
    await mutation({
      updateOneCampaign:[{
        where:{
          id
        },
        data:{
          assets:{
            set:[{
              id:file.id
            }]
          }
        }
      },{
        assetsIDs:true
      }]
    })
    refetch();
    toast.success("Media file uploaded successfully")
  }

  async function deployBooking(){
    for(const booking of campaignData?.campaign?.bookings||[]){
      await mutation({
        updateOneBooking:[{
          where:{
            id:booking.id
          },
          data:{
            assets:{
              set:campaignData?.campaign?.assets?.map(item=>({
                id:item.id
              }))
            }
          }
        },{
          id:true
        }]
      })
    }
    toast.success("Booking deployed successfully")
  }

  if(loading){
    return <Loader>
      <div>
        Fetching campaign details...
      </div>
    </Loader>
  }
  
  if(!loading && !campaignData?.campaign){
    return <div className="flex justify-center items-center h-screen">
      <h1 className="text-3xl font-semibold">Campaign not found</h1>
    </div>
  }

  return (
    <div className={`overflow-hidden relative`}>
      <Helmet>
        <title>Campaign | AdBoard Booking</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container relative py-4">
        <Heading desc='You can manage your campaigns here'>Manage your campaign</Heading>

        <div className="grid lg:grid-cols-3 grid-cols-1 gap-8">
          <div className="col-span-2 flex flex-col gap-4">

            <Box title="Campaign details">
              <div className="space-y-1 mt-4">
                <h2 className='text-lg mb-2 font-semibold'>Campaign name</h2>
                <Input placeholder="Name" value={campaignData?.campaign?.name} disabled={true}  />
              </div>

              <div className="space-y-1 mt-4">
                <h2 className='text-lg mb-2 font-semibold'>Industry</h2>
                <Input placeholder="Name" disabled={true} value={campaignData?.campaign?.businessCategory.name}/>
              </div>
             
              <br />

            </Box>
            <Box title="Creatives" classname=''>
              {/* <CreativesBox selectedAdboards={[]} creatives={creatives} setCreatives={setCreatives} /> */}
              <div className="space-y-1 mt-4 mb-4">
                <h2 className='text-lg mb-2 font-semibold'>Uploaded media file</h2>
                <div>
                  {campaignData?.campaign?.assets?.map(item=>{
                    if(item.type.startsWith("video")){
                      return <video className="min-h-[100px] max-h-[600px] my-4 rounded-lg " controls>
                        <source src={item.url} type={item.type}/>
                      </video>
                    }else{
                      return <img className="h-[200px] my-4 max-w-lg rounded-lg" src={item.url} alt="image description"/>
                    }
                  })}
                  {!campaignData?.campaign?.assets.length && <p className="text-sm mb-4 text-neutral-500 dark:text-neutral-400 mt-2">
                    You have not uploaded any media file yet.
                  </p>
                  }
                  <MediaLibraryPicker onCardSelect={file=>handleSave(file)}/>
                </div>
              </div>
              <LoadingButton onClick={deployBooking} className="">Deploy to all bookings</LoadingButton>
            </Box>

            <Box title="Bookings">
              <div className="pt-4">
                
                <Table className=''>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Adboard</TableHead>
                      <TableHead>City</TableHead>
                      <TableHead className="min-w-28 text-center">Cost</TableHead>
                      
                    </TableRow>
                  </TableHeader>
                  <TableBody className=''>
                    {campaignData?.campaign?.bookings.map((item)=>(  
                      <TableRow key="user1">
                        <TableCell>
                          <div className="flex gap-2 items-center">
                            <div className="w-16 h-16">

                              <NcImage src={item.adBoard.galleryImgs[0]} alt="adboard" />
                            </div>
                            <Link to={"/booking/"+item.id} target="_blank">
                              {item.adBoard.title}
                            </Link>
                          </div>
                        </TableCell>
                        <TableCell className="whitespace-nowrap ">
                          <div className="flex gap-2 items-center">
                            <LocationPointerIcon></LocationPointerIcon>
                            {item.adBoard.city}
                          </div>
                        </TableCell>
                        <TableCell className="text-center ">{item.priceSummary?.totalRent}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                  
              </div>
            </Box>
          </div>
          <div className="flex flex-col gap-4">
           
            <Box title="Pricing details">
              <form className="flex my-4 flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
                <StayDatesRangeInput
                  readOnly
                  // blockedDates={blockedDates}
                  wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
                  // onChange={handleDateChange}
                  fieldClassName="p-3"
                  defaultValue={selectedDate}
                  anchorDirection={"right"}
                  className="nc-ListingStayDetailPage__stayDatesRangeInput flex-1"
                />
              </form>

              <div className="flex flex-col gap-4 mt-2">
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>₹{order?.priceSummary.rentPerDay} x {order?.priceSummary.days} day</span>
                  <span>₹{order?.priceSummary.totalRent}</span>
                </div>

                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>GST (@{GST})</span>
                  <span>₹{order?.priceSummary.serviceCharge}</span>
                </div>

                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex justify-between font-semibold">
                  <span>Total</span>
                  <span>₹{order?.priceSummary.payableRent}</span>
                </div>
                
              </div>
             
            </Box>
          </div>
        </div>
      </div>

    </div>

  );
};

export default ManageCampaignPage;
