import { DateRage } from "@/components/HeroSearchForm/StaySearchForm";
import ModalSelectDate from "@/components/ModalSelectDate";
import { Moment } from "moment";
import React from "react";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import converSelectedDateToString from "@/utils/converSelectedDateToString";
import ModalReserveMobile from "./ModalReserveMobile";
import { AdboardFragment } from "./types";
import { FEES, FEES_AFTER_DISCOUNT, GST } from "@/contains/contants";
import { formatPrice } from "@/lib/utils";

type MobileFooterStickyProps={
  selectedDate:DateRage, 
  setSelectedDate:React.Dispatch<React.SetStateAction<DateRage>>,
  listingDetails:AdboardFragment,
  maxDate?:Moment,
  blockedDates?: Date[]
} 

const MobileFooterSticky = ({selectedDate, setSelectedDate,listingDetails, blockedDates,maxDate}:MobileFooterStickyProps) => {
  
  const [loading] = React.useState(false)

  const diff = (selectedDate.endDate?.diff(selectedDate.startDate, 'days') || 0) + 1;
  const rental = (listingDetails?.pricePerDay||0) * diff;
  const serviceCharge = 18 / 100 * rental;
  const total = rental + serviceCharge;

  const priceSummary = {
    rentPerDay: listingDetails?.pricePerDay,
    totalRent: rental,
    days: diff,
    serviceCharge,
    payableRent: total,
  }

  return (
    <div className="mobile-sticky-footer block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-20">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
            ₹{listingDetails?.pricePerDay}
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              /day
            </span>
          </span>          
          <ModalSelectDate
            maxDate={maxDate}
            blockedDates={blockedDates}
            defaultValue={selectedDate}
            onSelectDate={setSelectedDate}
            renderChildren={({  openModal }) => {
              return (
                <span
                  onClick={openModal}
                  className="block text-sm underline font-medium"
                >
                  {converSelectedDateToString(selectedDate)}
                </span>
              )}}
          />
        </div>

        <ModalReserveMobile
          selectedDate={selectedDate}
          priceSummary={priceSummary}
          listingDetails={listingDetails}
          renderModalContent={() => {

            return <div className="flex gap-2 flex-col">
             
              <div>
                <div className="flex flex-col space-y-4">
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>Price</span>
                    <span><span className="block text-xl font-semibold">
                ₹{listingDetails?.pricePerDay}
                      <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                  /day
                      </span>
                    </span></span>
                  </div>
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>Selected dates</span>
                    <span><ModalSelectDate
                      maxDate={maxDate}
                      blockedDates={blockedDates}
                      defaultValue={selectedDate}
                      onSelectDate={setSelectedDate}
                      renderChildren={({  openModal }) => {
                        return (
                          <span
                            onClick={openModal}
                            className="block text-sm underline font-medium"
                          >
                            {converSelectedDateToString(selectedDate)}
                          </span>
                        )}}
                    /></span>
                  </div>
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>₹{listingDetails?.pricePerDay} x {diff} days</span>
                    <span>₹{rental}</span>
                  </div>
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>GST (@{GST})</span>
                    <span>₹{serviceCharge}</span>
                  </div>
                  <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                  <div className="flex justify-between font-semibold">
                    <span>Total</span>
                    <span>₹{total}</span>
                  </div>
                  <div className="flex justify-between font-normal">
                    <span>Pay now (platform fees)</span>
                    <span><s>{formatPrice(FEES)}</s> {formatPrice(FEES_AFTER_DISCOUNT)} + GST</span>
                  </div>
                  <div className="flex justify-between font-normal">
                    <span>Pay on booking approval</span>
                    <span>₹{total}</span>
                  </div>
                </div>
              </div>
             
            </div>
          }}
          renderChildren={({ openModal }) => {
            return (
              <>
                <ButtonPrimary
                  loading = {loading}
                  sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                  onClick={()=>openModal(listingDetails?.id||"")}
                >
              Reserve
                </ButtonPrimary>
               
              </>
            )}}
        />
      </div>
    </div>
  );
};

export default MobileFooterSticky;
